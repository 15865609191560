import React from 'react';
import { FormControl, Button } from 'react-bootstrap';

const CustomMenu = (props) => {
	const { children } = props;

	let bool = React.Children
		.toArray(children)
		.filter((child) => !props.value || child.props.children.toLowerCase().startsWith(props.value));

	return (
		<div className="dropdown">
			<div className="flex-x">
				<FormControl
					placeholder="&#xF002; Filter Companies"
					className="mx-3 my-2 w-auto search-box fontAwsome"
					onChange={props.handleChange}
					value={props.value}
				/>
				<Button className="btn bg-transparent clear-button-black" onClick={(e) => props.clearSearchBar(e)}>
					<i className="fa fa-times" />
				</Button>
			</div>

			<div
				className={
					props.value && bool && bool.length ? (
						'd-down-style dropdown-menu show mx-4 my-2 w-auto'
					) : (
						'd-down-style hidden dropdown-menu mx-4 my-2 w-auto'
					)
				}
			>
				{React.Children
					.toArray(children)
					.filter((child) => !props.value || child.props.children.toLowerCase().startsWith(props.value))}
			</div>
		</div>
	);
};

export default CustomMenu;

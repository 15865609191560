import React from 'react';
import { Checkbox } from 'pretty-checkbox-react';

const CustomToggleList = ({ columns, onColumnToggle, toggles, columnToggle }) => {
	columns.map((column) => {
		if (!toggles.hasOwnProperty('' + column.dataField + '')) {
			toggles[column.dataField] = true;
		}
	});

	const handleColumnToggle = (dataField) => {
		onColumnToggle(dataField);
		columnToggle(dataField);
	};

	return (
		<div className="btn-group btn-group-toggle btn-group-vertical toggler" data-toggle="buttons">
			{columns
				.map((column) => ({
					...column,
					toggle: toggles[column.dataField]
				}))
				.map((column, key) => {
					return (
						<div className="ml-2" key={key}>
							<Checkbox
								animation="smooth"
								key={column.dataField}
								shape="round"
								color="none"
								icon={<i className="fa fa-check" />}
								onChange={() => handleColumnToggle(column.dataField)}
								checked={column.toggle}
							>
								<label className="form-check-label">{column.text}</label>
							</Checkbox>
						</div>
					);
				})}
		</div>
	);
};

export default CustomToggleList;

import columnToggleListActions from './actions';

const initState = {
	columns: localStorage.getItem('columnToggleList') ? JSON.parse(localStorage.getItem('columnToggleList')) : [],
	userColumns: localStorage.getItem('userColumnToggleList')
		? JSON.parse(localStorage.getItem('userColumnToggleList'))
		: []
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case columnToggleListActions.SELECTED_COLUMNS: {
			return {
				...state,
				columns: action.columns
			};
		}

		case columnToggleListActions.SELECTED_USERCOLUMNS: {
			return {
				...state,
				userColumns: action.userColumns
			};
		}

		default:
			return state;
	}
}

import React, { useState } from 'react';
import Sidebar from 'components/sidebar/Sidebar';
import dashboardRoutes from 'routes/dashboardRoutes.jsx';
import { ProtectedRoutes } from 'routes/protectedRoutes.jsx';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Company from 'views/Company/Company';
import ListUsers from 'views/Company/ListUsers';
import Profile from 'views/Profile/Profile';
import Custom from 'views/Messages/Custom';
import { drawerWidth, minidrawerWidth } from 'variables/helper';
import Footer from 'components/footer/footer';

const DashboardLayout = (props) => {
	const backgroundColor = 'vabotu';
	const activeColor = 'info';
	const [ mini, setMini ] = useState(false);
	const [ drawerClasses, setDrawerClasses ] = useState('sidebar open');

	const toggleDrawer = () => {
		if (mini) {
			setDrawerClasses('sidebar closed');
		} else {
			setDrawerClasses('sidebar open');
		}
		setMini(!mini);
	};
	const mainpanelWidth = {
		width: mini ? `calc(100% - ${drawerWidth})` : `calc(100% - ${minidrawerWidth})`
	};
	return (
		<div className="wrapper toggled">
			<Sidebar
				routes={dashboardRoutes}
				{...props}
				mini={mini}
				drawerWidth={drawerWidth}
				minidrawerWidth={minidrawerWidth}
				drawerClasses={drawerClasses}
				bgColor={backgroundColor}
				activeColor={activeColor}
				toggleDrawer={() => toggleDrawer}
			/>
			<div className="main-panel" style={mainpanelWidth}>
				<Switch>
					<ProtectedRoutes {...props}>
						{dashboardRoutes.map((prop, key) => {
							if (prop.pro) {
								return null;
							}
							if (prop.redirect) {
								return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
							}
							return <Route path={prop.path} component={prop.component} key={key} />;
						})}
						<Route path="/company" component={Company} />
						<Route path="/viewUsers" component={ListUsers} />
						<Route path="/profile" component={Profile} />
						<Route path="/custom" component={Custom} />
					</ProtectedRoutes>
				</Switch>
				<Footer />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			token: state.auth.accessToken,
			isLogin: state.auth.isLogin,
			authUser: state.auth.authUser
		}
	};
};

export default connect(mapStateToProps)(DashboardLayout);

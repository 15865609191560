import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import logo from 'vabotu_logo.svg';
import usrImage from 'assets/default-avatar.png';
import Navbar from 'react-bootstrap/Navbar';
import UserPanel from './userPanel';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import AuthActions from 'redux/auth/actions';
import API from '../../api';

const { logout } = AuthActions;

const Sidebar = (props) => {
	const [ admin, setAdmin ] = useState({ id: '', name: '', email: '' });
	const [ userImage, setUserImage ] = useState(usrImage);

	useEffect(
		() => {
			API.get('/admin', { headers: { Authorization: props.authData.token } })
				.then((response) => {
					const authAdmin = {
						id: response.data.data.id,
						name: response.data.data.name,
						email: response.data.data.email
					};
					setAdmin(authAdmin);
					if (response.data.data.img) {
						setUserImage(process.env.REACT_APP_REST_API + response.data.data.img);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[ props.userDetail ]
	);

	const activeRoute = (routeName) => {
		return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
	};

	const handleLogout = () => {
		const encodeForm = (data) => {
			return Object.keys(data)
				.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		};
		const userData = {
			id: admin.id,
			action: 'logout'
		};
		API.post('/actionLog', encodeForm(userData), {
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*',
				Authorization: props.authData.token
			}
		})
			.then((response) => {
				props.logout();
				props.history.push('/login');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const { mini, drawerWidth, minidrawerWidth } = props;
	const sidebarWidth = {
		width: mini ? drawerWidth : minidrawerWidth
	};
	return (
		<Navbar bg="light" expand="lg" expanded={false} onToggle={props.toggleDrawer()}>
			<Navbar.Collapse id="basic-navbar-nav">
				<div
					style={sidebarWidth}
					className="sidebar"
					data-color={props.bgColor}
					data-active-color={props.activeColor}
				>
					<div className="logo">
						<Navbar.Toggle onClick={props.toggleDrawer()} aria-controls="basic-navbar-nav">
							<i className="fa fa-outdent" area-hidden="true" />
						</Navbar.Toggle>
						<a
							href="https://admin.vabotu.com/"
							className={mini ? 'simple-text logo-normal' : 'simple-text logo-normal hide'}
						>
							<div className="logo-img">
								<img src={logo} alt="Vabotu-logo" />
							</div>
						</a>
					</div>

					<div className="sidebar-wrapper" style={sidebarWidth}>
						<Nav>
							{props.routes.map((prop, key) => {
								if (prop.redirect) return null;
								return (
									<li className={activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')} key={key}>
										<NavLink to={prop.path} className="nav-link" activeClassName="active">
											<i className={prop.icon} size="5px" />
											<p className={mini ? null : 'hide'}>{prop.name}</p>
										</NavLink>
									</li>
								);
							})}
						</Nav>
					</div>
					<UserPanel
						source={userImage}
						user={admin}
						class={mini ? 'flex-1' : 'hide'}
						logout={() => handleLogout()}
						clicked={() => props.history.push('/profile')}
					/>
				</div>
			</Navbar.Collapse>
		</Navbar>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			token: state.auth.accessToken,
			isLogin: state.auth.isLogin
		},
		userDetail: {
			userImage: state.userDetail.userImage,
			username: state.userDetail.username
		}
	};
};

export default compose(withRouter, connect(mapStateToProps, { logout }))(Sidebar);

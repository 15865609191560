import showUserListActions from './actions';

const initState = {
	showUserList: null
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case showUserListActions.SELECTED_COMPANY:
			return {
				...state,
				showUserList: action.company
			};
		default:
			return state;
	}
}

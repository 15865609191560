import React, { useState } from 'react';
import { Checkbox } from 'pretty-checkbox-react';
import { Button, Collapse } from 'react-bootstrap';
import UsersModal from './usersModal';

const tableRow = (props) => {
	const [ open, setOpen ] = useState(false);
	const [ modalShow, setModalShow ] = useState(false);

	const handleToggle = (open, event) => {
		setOpen(!open);
		if (event.target.getAttribute('class') === 'fa fa-plus') {
			event.target.setAttribute('class', 'fa fa-minus');
		} else if (event.target.getAttribute('class') === 'fa fa-minus') {
			event.target.setAttribute('class', 'fa fa-plus');
		} else {
			event.target.setAttribute('class', 'fa fa-plus');
		}
	};

	let modalClose = () => setModalShow(false);

	let users = props.users.slice(0, 4);
	return (
		<tr className="p-2">
			<td>
				<Checkbox
					animation="smooth"
					shape="round"
					color="primary-o"
					checked={props.selectedCompanyChecks.includes(props.companyID)}
					onChange={(e) => props.handleSingleCompanyChecked(e)}
					value={props.companyID}
				/>
				<Button
					aria-controls="example-collapse-text-6"
					variant="none"
					aria-expanded={open}
					className="expand-button"
				>
					<i className="fa fa-plus" onClick={(e) => handleToggle(open, e)} />
				</Button>
				<label className="form-check-label">{props.companyName}</label>
			</td>
			<td>
				<Checkbox
					animation="smooth"
					shape="round"
					color="primary-o"
					checked={props.selected.includes(props.adminName[0].email)}
					value={props.adminName[0].email}
					onChange={(e) => props.handleChecked(e, props.adminName[0] && props.adminName[0])}
				>
					<label className="form-check-label">
						{props.adminName[0].first + ' ' + props.adminName[0].last}
					</label>
				</Checkbox>
			</td>
			<td>
				<Checkbox
					animation="smooth"
					shape="round"
					color="primary-o"
					checked={props.selectedUserCountChecks.includes(props.companyID + '' + props.users.length)}
					value={props.companyID + '' + props.users.length}
					onChange={(e) => props.handleAllUsers(e, props.users)}
				>
					<label className="form-check-label">{props.users.length}</label>
				</Checkbox>
				<Collapse in={open}>
					<div className="flex-y" id="example-collapse-text-6">
						{users &&
							users.length &&
							users.map((user, key) => {
								return (
									<Checkbox
										animation="smooth"
										shape="round"
										color="primary-o"
										key={key}
										value={user.email}
										onChange={(e) => props.handleChecked(e,user && user)}
										checked={props.selected.includes(user.email)}
									>
										<label className="form-check-label">{user.first + ' ' + user.last}</label>
									</Checkbox>
								);
							})}
						{props.users.length > 5 ? (
							<div className="table-link" onClick={() => setModalShow(true)}>
								More
							</div>
						) : null}

						<UsersModal
							show={modalShow}
							onHide={modalClose}
							users={props.users}
							selected={props.selected}
							checked={props.handleChecked}
						/>
					</div>
				</Collapse>
			</td>
		</tr>
	);
};

export default tableRow;

import selectCustomUsersActions from './actions';

const initState = {
	selectedUsers: [],
	selectedUsersId: []
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case selectCustomUsersActions.SELECTED_USERS:
			return {
				...state,
				selectedUsers: action.users
			};
		case selectCustomUsersActions.SELECTED_USERS_ID:
			return {
				...state,
				selectedUsersId: action.users_id
			};
		default:
			return {
				...state,
				selectedUsers: action.users,
				selectedUsersId: action.users_id
			};
	}
}

import React, { useState, useEffect } from 'react';
import User from 'assets/default-avatar.png';
import lock from 'assets/settings-lock.svg';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import UserDeailsActions from 'redux/userDetail/actions';
import API from '../../api';

const { setUserImage, setUsername } = UserDeailsActions;

const Profile = (props) => {
	const [ user, setUser ] = useState({ name: '', email: '', img: null });
	const [ password, setPassword ] = useState({ old_password: '', new_password: '', confirm_password: '' });
	const [ passwordError, setPasswordError ] = useState(null);
	const [ passwordSuccess, setPasswordSuccess ] = useState(null);
	const [ profileError, setProfileError ] = useState(null);
	const [ profileSuccess, setProfileSuccess ] = useState(null);
	const [ error, setError ] = useState(null);
	const [ preview, setPreview ] = useState(User);
	const [ src, setSrc ] = useState(null);

	useEffect(() => {
		API.get('/admin', { headers: { Authorization: props.authData.accessToken } })
			.then((response) => {
				const authAdmin = {
					name: response.data.data.name,
					email: response.data.data.email
				};
				setUser(authAdmin);
				if (response.data.data.img) {
					setPreview(process.env.REACT_APP_REST_API + response.data.data.img);
				}
			})
			.catch((err) => {
				setError(err.message);
			});
	}, []);

	const handleUpdateProfile = (event) => {
		event.preventDefault();

		let userData = {
			name: user.name
		};

		const encodeForm = (data) => {
			return Object.keys(data)
				.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		};

		if (user.name === '') {
			setProfileError('Please enter valid name');
		}

		if (src) {
			let formData = new FormData();
			formData.append('img', src);
			API.post('/updateimage', formData, {
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'multipart/form-data',
					'Access-Control-Allow-Origin': '*',
					Authorization: props.authData.accessToken
				}
			})
				.then((res) => {
					setPreview(process.env.REACT_APP_REST_API + res.data.data);
					props.setUserImage(res.data.data);
					setSrc(null);
					if (res.data.status === 'Error') {
						setProfileError(res.data.msg);
					} else {
						setProfileSuccess(res.data.msg);
					}
					return res;
				})
				.catch((err) => {
					setProfileError(err.message);
				});
		}

		API.put('/updateprofile', encodeForm(userData), {
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*',
				Authorization: props.authData.accessToken
			}
		})
			.then((res) => {
				var admin = user;
				admin.name = res.data.data;
				setUser(admin);
				props.setUsername(admin.name);
				setSrc(null);
				if (res.data.status === 'Error') {
					setProfileError(res.data.msg);
				} else {
					setProfileSuccess(res.data.msg);
				}
				return res;
			})
			.catch((err) => {
				setProfileError(err.message);
			});
	};

	const handleChangePassword = (event) => {
		event.preventDefault();
		var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
		if (password.new_password !== password.confirm_password) {
			setPasswordError("Password Dosen't match");
		} else if (!password.new_password.match(decimal)) {
			setPasswordError(
				'Input Password which contains min 8 characters, at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
			);
		} else {
			let formData = {
				old_password: password.old_password,
				new_password: password.new_password,
				confirm_password: password.confirm_password
			};

			const encodeForm = (data) => {
				return Object.keys(data)
					.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
					.join('&');
			};

			API.post('/changepassword', encodeForm(formData), {
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
					Authorization: props.authData.accessToken
				}
			})
				.then((res) => {
					setPassword({ old_password: '', new_password: '', confirm_password: '' });

					if (res.data.status === 'Error') {
						setPasswordError(res.data.msg);
					} else {
						setPasswordSuccess(res.data.msg);
					}
				})
				.catch((err) => {
					setPasswordError(err.message);
				});
		}
	};

	const openFileHandler = () => {
		var file = document.getElementById('file-uploader');
		file.click();
	};

	const handleInput = (input, event) => {
		let state = Object.assign({}, user);
		state[input] = event.target.value;
		setUser(state);
	};

	const handlePasswordInput = (input, event) => {
		let state = Object.assign({}, password);
		state[input] = event.target.value;
		setPassword(state);
		setPasswordError(null);
		setPasswordSuccess(null);
	};

	const handleImageChange = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setSrc(file);
			setPreview(reader.result);
		};
		reader.readAsDataURL(file);
	};

	return (
		<div className="content justify-content-md-center">
			<h5>My Profile</h5>
			<div className="flex-x center">
				<div className="flex-0 p-2">
					<img src={preview} alt="User-Profile" className="user-img-lg" onClick={() => openFileHandler()} />
				</div>
				<div className="flex-1 p-2">
					<Form className="col-md-6" onSubmit={(e) => handleUpdateProfile(e)}>
						<input type="file" id="file-uploader" onChange={(e) => handleImageChange(e)} hidden />
						<span className="error">{error}</span>
						<span className="error">{profileError}</span>
						<span className="msg">{profileSuccess}</span>
						<div className="flex-x align-center custom-input mb-2">
							<Form.Label>Name:</Form.Label>
							<Form.Control
								className="form-control-lg"
								type="text"
								value={user.name}
								onChange={(e) => handleInput('name', e)}
								required
							/>
						</div>
						<div className="flex-x align-center custom-input mb-2">
							<Form.Label>Email:</Form.Label>
							<Form.Control className="form-control-lg" type="text" value={user.email} readOnly />
						</div>
						<Button variant="success" type="submit" className="float-right">
							Update
						</Button>
					</Form>
				</div>
			</div>
			<hr className="line" />
			<h5>Change Password</h5>
			<div className="flex-x">
				<div className="flex-0 p-2">
					<img src={lock} alt="Password" className="user-img-lg" />
				</div>
				<div className="flex-1 p-2">
					<span className="error">{passwordError}</span>
					<span className="msg">{passwordSuccess}</span>
					<Form className="col-md-6" onSubmit={(e) => handleChangePassword(e)}>
						<div className="flex-x align-center custom-input mb-2">
							<Form.Label>Old Password:</Form.Label>
							<input
								className="form-control form-control-lg"
								type="password"
								onChange={(e) => handlePasswordInput('old_password', e)}
								required
							/>
						</div>
						<div className="flex-x align-center custom-input mb-2">
							<Form.Label>New Password:</Form.Label>
							<input
								className="form-control form-control-lg"
								type="password"
								onChange={(e) => handlePasswordInput('new_password', e)}
								required
							/>
						</div>
						<div className="flex-x align-center custom-input mb-2">
							<Form.Label>Confirm Password:</Form.Label>
							<input
								className="form-control form-control-lg"
								type="password"
								onChange={(e) => handlePasswordInput('confirm_password', e)}
								required
							/>
						</div>
						<Button variant="success" type="submit" className="float-right">
							Update
						</Button>
					</Form>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			isLogin: state.auth.isLogin,
			accessToken: state.auth.accessToken
		}
	};
};

export default compose(withRouter, connect(mapStateToProps, { setUserImage, setUsername }))(Profile);

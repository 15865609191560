const setUserDeailsActions = {
	USERIMAGE: 'USERIMAGE',
	USERNAME: 'USERNAME',

	setUserImage: (userImage) => {
		return {
			type: setUserDeailsActions.USERIMAGE,
			userImage: userImage
		};
	},

	setUsername: (username) => {
		return {
			type: setUserDeailsActions.USERNAME,
			username: username
		};
	}
};

export default setUserDeailsActions;

import React, { useState, useEffect } from 'react';
import { Button, Row, Card, Dropdown, DropdownButton } from 'react-bootstrap';
import Column from 'components/dashboard/Column';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Line, Doughnut } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import API from '../../api';
import RecipientButtonActions from 'redux/message/actions';
import CustomSelectedUserAction from 'redux/customMessage/actions';
import ShowDashboardActions from 'redux/dashboard/actions';
import CustomMenu from 'components/dashboard/CustomMenu';
import socket from '../../socket-cluster/index';

const { showDashboard, selectOption } = ShowDashboardActions;
const { selectedRecipientsButton } = RecipientButtonActions;
const { selectUsers } = CustomSelectedUserAction;

const Dashboard = (props) => {
	const colors = [ '#1f89fc', '#64b9fc', '#a9c6f9', '#48d2ec', '#bdc3c7', '#ff86af' ];
	const [ selected, setSelected ] = useState([]);
	const [ title, setTitle ] = useState('');
	const [ lineData, setLineData ] = useState(null);
	const [ areaData, setAreaData ] = useState(null);
	const [ pieData, setPieData ] = useState(null);
	const [ companydata, setCompanydata ] = useState(null);
	const [ columnCheck, setColumnCheck ] = useState(1);
	const [ filterKey, setFilterKey ] = useState(0);
	const [ allCompaniesButtonText, setAllCompaniesButtonText ] = useState('Overview');
	const [ startDate, setStartDate ] = useState(new Date());
	const [ endDate, setEndDate ] = useState(new Date());
	const [ csvData, setCsvData ] = useState([]);
	const [ companiesDropdown, setCompaniesDropdown ] = useState(null);
	const [ dropdownButtonTitle, setDropdownButtonTitle ] = useState('All Time');
	const [ value, setValue ] = useState('');

	useEffect(
		() => {
			if (filterKey === 0 && !props.company) {
				// GENERAL FILTER SELECTED AND NO COMPANY SELECTED
				API.get('/home', { headers: { Authorization: props.authData.accessToken } })
					.then(async (res) => {
						setCompanyDetailMethod(res.data.data);
						setCompaniesDropdown(res.data.companies);
						setAllCompaniesButtonText('Overview');
					})
					.catch((err) => err);
			} else if (filterKey !== -1) {
				// NO CUSTOM FILTER AND NO COMPANY SELECTED
				if (props.company && props.company.id) {
					var url = '/home/company/' + filterKey + '/'; // FILTER SELECTED AND COMPANY SELECTED

					if (filterKey === 0) url = '/home/company/'; // GENERAL FILTER SELECTED AND COMPANY SELECTED

					API.get(url + props.company.id, {
						headers: { Authorization: props.authData.accessToken }
					})
						.then(async (res) => {
							setSingleCompanyDetailMethod(res.data.data);
							setCompaniesDropdown(res.data.companies);
							setAllCompaniesButtonText(props.company.company_name);
						})
						.catch((err) => err);
				} else {
					// FILTER SELECTED AND NO COMPANY
					API.get('/home/' + filterKey, { headers: { Authorization: props.authData.accessToken } })
						.then(async (res) => {
							setCompanyDetailMethod(res.data.data);
							setCompaniesDropdown(res.data.companies);
							setAllCompaniesButtonText('Overview');
						})
						.catch((err) => err);
				}
			} else {
			}
			props.selectedRecipientsButton(null);
			props.selectUsers(null);
			props.selectOption(null);
		},
		[ filterKey, props.company ]
	);

	useEffect(
		() => {
			selected.map((d, key) => {
				lineChartData.push({
					fill: false,
					label: d.name + ' ' + d.count,
					borderColor: colors[key],
					pointHoverBorderWidth: 3,
					pointHoverBackgroundColor: colors[key],
					data: d.data
				});

				areaChartData.push({
					label: d.name,
					borderColor: colors[key],
					backgroundColor: hexToRgbA(colors[key]),
					pointHoverBorderWidth: 3,
					pointHoverBackgroundColor: colors[key],
					data: d.data
				});

				pieChartData.push(d.count);
				pieChartLables.push(d.name);
				pieColor.push(colors[key]);
				if (lineChartData.length === selected.length) {
					setLineData({
						datasets: lineChartData
					});
				}

				if (areaChartData.length === selected.length) {
					setAreaData({
						datasets: areaChartData
					});
				}

				if (pieChartData.length && pieChartLables.length) {
					setPieData({
						datasets: [
							{
								data: pieChartData,
								borderColor: pieColor,
								backgroundColor: pieColor
							}
						],
						labels: pieChartLables
					});
				}
				return d;
			});
		},
		[ selected ]
	);

	const handleChange = (e) => {
		setValue(e.target.value.toLowerCase().trim());
	};

	const clearSearchBar = () => {
		setValue('');
		props.showDashboard(null);
	};

	let lineChartData = [];
	let areaChartData = [];
	let pieChartData = [];
	let pieChartLables = [];
	let pieColor = [];

	const setCompanyDetailMethod = (data) => {
		let companydetail = [
			{
				id: 1,
				key: 0,
				titlecolor: '#1F89FC',
				title: 'Company Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Total Companies',
						count: data['active_company']['active_companies_count'],
						checkcolor: 'success-o',
						data: data['active_company']['active_companies_data'],
						tooltip: 'Active companies( active companies from studio, agency, free, paid)'
					},
					{
						id: 2,
						name: 'Paid',
						count: data['paid_company']['paid_company_count'],
						checkcolor: 'info-o',
						data: data['paid_company']['paid_companies_data'],
						tooltip:
							'Paid companies(studio, agency, previous version paid companies and paid-inactive companies)'
					},
					{
						id: 3,
						name: 'Closed',
						count: data['closed_company']['closed_company_count'],
						checkcolor: 'danger-o',
						data: data['closed_company']['closed_companies_data'],
						tooltip: 'Closed Accounts'
					},
					{
						id: 4,
						name: 'Free Accounts',
						count: data['free_company']['free_company_count'],
						checkcolor: 'warning-o',
						data: data['free_company']['free_companies_data'],
						tooltip: 'Free Trial, trial expired, free companies and free-inactive companies'
					},
					{
						id: 5,
						name: 'Studio',
						count: data['studio_company']['studio_companies_count'],
						checkcolor: 'warning-o',
						data: data['studio_company']['studio_companies_data'],
						tooltip: 'Studio plan companies(includes studio-inactive companies)'
					},
					{
						id: 6,
						name: 'Agency',
						count: data['agency_company']['agency_companies_count'],
						data: data['agency_company']['agency_companies_data'],
						tooltip: 'Agency plan companies(includes agency-inactive companies)'
					}
				]
			},
			{
				id: 2,
				key: 1,
				titlecolor: '#A97FB3',
				title: 'Users Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Total Users',
						count: data['total_users']['total_users_count'],
						checkcolor: 'success-o',
						data: data['total_users']['total_users_data'],
						tooltip: 'All users(active, inactive, admin, owner)'
					},
					{
						id: 2,
						name: 'Active Users',
						count: data['active_users']['active_users_count'],
						checkcolor: 'info-o',
						data: data['active_users']['active_users_data'],
						tooltip: 'Active users from active company'
					},
					{
						id: 3,
						name: 'Pending Invitations',
						count: data['pending_invitations']['invitations_count'],
						checkcolor: 'danger-o',
						data: data['pending_invitations']['invitations_data'],
						tooltip: "Invitations sent but haven't signed up"
					}
				]
			},
			{
				id: 3,
				key: 2,
				titlecolor: '#00C486',
				title: 'Messaging Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Messages Sent',
						count: data['messages_sent']['messages_sent_count'],
						checkcolor: 'success-o',
						data: data['messages_sent']['messages_sent_data']
					},
					{
						id: 2,
						name: 'Files Shared',
						count: data['files_shared']['medias_count'],
						checkcolor: 'info-o',
						data: data['files_shared']['medias_data']
					},
					{
						id: 3,
						name: 'Image Comments',
						count: data['media_comments']['media_comments_count'],
						checkcolor: 'danger-o',
						data: data['media_comments']['media_comments_data']
					},
					{
						id: 4,
						name: 'Invitations Sent',
						count: data['invitation_sent']['invitations_sent_count'],
						checkcolor: 'warning-o',
						data: data['invitation_sent']['invitations_sent_data']
					},
					{
						id: 5,
						name: 'Workspaces Created',
						count: data['workspaces']['workspaces_count'],
						data: data['workspaces']['workspaces_data']
					}
				]
			},
			{
				id: 4,
				key: 3,
				titlecolor: '#FF0056',
				title: 'Tasks Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Boards Created',
						count: data['boards']['boards_count'],
						checkcolor: 'success-o',
						data: data['boards']['boards_data']
					},
					{
						id: 2,
						name: 'Columns Created',
						count: data['columns']['columns_count'],
						checkcolor: 'info-o',
						data: data['columns']['columns_data']
					},
					{
						id: 3,
						name: 'Tasks Created',
						count: data['tasks_created']['tasks_created_count'],
						checkcolor: 'danger-o',
						data: data['tasks_created']['tasks_created_data']
					},
					{
						id: 4,
						name: 'Tasks Assigned',
						count: data['tasks_assigned']['tasks_assigned_count'],
						checkcolor: 'warning-o',
						data: data['tasks_assigned']['tasks_assigned_data']
					},
					{
						id: 5,
						name: 'Tasks Completed',
						count: data['tasks_completed']['tasks_completed_count'],
						data: data['tasks_completed']['tasks_completed_data']
					}
				]
			}
		];
		setCompanydata(companydetail);
		let filter_data = [];
		companydetail &&
			companydetail.length &&
			companydetail.filter((data, key) => {
				data.data.filter((d) => {
					filter_data.push({ count: d.count, name: d.name });
					return { name: d.name, count: d.count };
				});
				return null;
			});
		setCsvData(filter_data);

		if (companydetail) {
			handleChecked(1, companydetail[0]);
		}
	};

	const setSingleCompanyDetailMethod = (data) => {
		let companydetail = [
			{
				id: 1,
				key: 1,
				titlecolor: '#A97FB3',
				title: 'Users Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Total Users',
						count: data['total_users']['total_users_count'],
						checkcolor: 'success-o',
						data: data['total_users']['total_users_data'],
						tooltip: 'All users(active, inactive, admin, owner)'
					},
					{
						id: 2,
						name: 'Active Users',
						count: data['active_users']['active_users_count'],
						checkcolor: 'info-o',
						data: data['active_users']['active_users_data'],
						tooltip: 'Active users from active company'
					},
					{
						id: 3,
						name: 'Pending Invitations',
						count: data['pending_invitations']['invitations_count'],
						checkcolor: 'danger-o',
						data: data['pending_invitations']['invitations_data']
					}
				]
			},
			{
				id: 2,
				key: 2,
				titlecolor: '#00C486',
				title: 'Messaging Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Messages Sent',
						count: data['messages_sent']['messages_sent_count'],
						checkcolor: 'success-o',
						data: data['messages_sent']['messages_sent_data']
					},
					{
						id: 2,
						name: 'Files Shared',
						count: data['files_shared']['medias_count'],
						checkcolor: 'info-o',
						data: data['files_shared']['medias_data']
					},
					{
						id: 3,
						name: 'Image Comments',
						count: data['media_comments']['media_comments_count'],
						checkcolor: 'danger-o',
						data: data['media_comments']['media_comments_data']
					},
					{
						id: 4,
						name: 'Invitations Sent',
						count: data['invitation_sent']['invitations_sent_count'],
						checkcolor: 'warning-o',
						data: data['invitation_sent']['invitations_sent_data']
					},
					{
						id: 5,
						name: 'Workspaces Created',
						count: data['workspaces']['workspaces_count'],
						data: data['workspaces']['workspaces_data']
					}
				]
			},
			{
				id: 3,
				key: 3,
				titlecolor: '#FF0056',
				title: 'Tasks Data',
				checkcolor: 'primary-o',
				data: [
					{
						id: 1,
						name: 'Boards Created',
						count: data['boards']['boards_count'],
						checkcolor: 'success-o',
						data: data['boards']['boards_data']
					},
					{
						id: 2,
						name: 'Columns Created',
						count: data['columns']['columns_count'],
						checkcolor: 'info-o',
						data: data['columns']['columns_data']
					},
					{
						id: 3,
						name: 'Tasks Created',
						count: data['tasks_created']['tasks_created_count'],
						checkcolor: 'danger-o',
						data: data['tasks_created']['tasks_created_data']
					},
					{
						id: 4,
						name: 'Tasks Assigned',
						count: data['tasks_assigned']['tasks_assigned_count'],
						checkcolor: 'warning-o',
						data: data['tasks_assigned']['tasks_assigned_data']
					},
					{
						id: 5,
						name: 'Tasks Completed',
						count: data['tasks_completed']['tasks_completed_count'],
						data: data['tasks_completed']['tasks_completed_data']
					}
				]
			}
		];

		setCompanydata(companydetail);
		if (companydetail) {
			handleChecked(1, companydetail[0]);
		}
	};

	function hexToRgbA(hex) {
		var c;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length === 3) {
				c = [ c[0], c[0], c[1], c[1], c[2], c[2] ];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [ (c >> 16) & 255, (c >> 8) & 255, c & 255 ].join(',') + ',0.4)';
		}
		throw new Error('Bad Hex');
	}

	const lineOptions = {
		showLines: true,
		borderWidth: 1,
		elements: {
			line: {
				tension: 0 // disables bezier curves
			}
		},
		legend: {
			display: true,
			labels: {
				fontColor: '#92aac4'
			}
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false
					},
					type: 'time',
					time: {
						unit: 'day'
					},
					distribution: 'series'
				}
			],
			yAxes: [
				{
					position: 'right'
				}
			]
		}
	};

	const areaOptions = {
		// borderWidth: 1,
		legend: {
			display: true
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false
					},
					type: 'time',
					time: {
						unit: 'day'
					},
					distribution: 'series'
				}
			],
			yAxes: [
				{
					gridLines: {
						display: false
					},
					position: 'right'
				}
			]
		}
	};

	const exportToPNG = () => {
		var png_url_base64 = document.getElementById('myChart').toDataURL('image/png');
		downloadURL(png_url_base64, 'png');
	};

	const downloadURL = (imgData, type) => {
		var a = document.createElement('a');
		if (type === 'png') {
			a.href = imgData.replace('image/png');
			a.download = 'LineChart.png';
		} else {
			a.href = imgData.replace('text/csv');
			a.download = 'LineChart.csv';
		}
		a.click();
	};

	const handleChecked = (id, { data, title }) => {
		setColumnCheck(id);

		// let temp =
		// 	data &&
		// 	data.length &&
		// 	data.map((d) => {
		// 		return d;
		// 	});

		// setCsvData(temp);
		setSelected(data);
		setTitle(title);
	};

	const handleChangeEnd = (e) => {
		setEndDate(() => e);
	};

	const handleChangeStart = (e) => {
		setStartDate(() => e);
	};

	const filterDataCustomDateWise = () => {
		var start_date = startDate;
		var end_date = endDate;
		var iso_startdate_string = start_date.toISOString();
		var iso_enddate_string = end_date.toISOString();

		if (props.company && props.company.id) {
			// CUSTOM FILTER SELECTED AND COMPANY SELECTED
			API.get(
				'/home/custom/company/' + props.company.id + '/' + iso_startdate_string + '/' + iso_enddate_string,
				{
					headers: { Authorization: props.authData.accessToken }
				}
			)
				.then((res) => {
					setSingleCompanyDetailMethod(res.data.data);
					setCompaniesDropdown(res.data.companies);
				})
				.catch((err) => err);
		} else {
			// CUSTOM FILTER SELECTED AND NO COMPANY
			API.get('/home/custom/' + iso_startdate_string + '/' + iso_enddate_string, {
				headers: { Authorization: props.authData.accessToken }
			})
				.then((res) => {
					setCompanyDetailMethod(res.data.data);
					setCompaniesDropdown(res.data.companies);
				})
				.catch((err) => err);
		}
	};

	const redirectToCompany = (name) => {
		props.selectOption(name);
		props.history.push('/company');
	};

	const redirectToUsers = (name) => {
		props.selectOption(name);
		props.history.push('/viewUsers');
	};

	const handleSearchInput = (event, company) => {
		props.showDashboard({ id: company.id, company_name: company.name });
		setValue(company.name);
		handleDropdownButtonSelect('All Time', 0);
		props.history.push('/home');
	};

	const handleDropdownButtonSelect = (title, filterKey) => {
		setFilterKey(() => filterKey);
		setDropdownButtonTitle(() => title);
	};

	const handleRefreshData = () => {
		API.get('/refresh', {
			headers: { Authorization: props.authData.accessToken }
		})
			.then((res) => {
				window.location.reload(false);
			})
			.catch((err) => err);
	};
	return (
		<div className="content justify-content-md-center">
			<div className="flex-x">
				<div className="flex-x align-center custom-dropdown-button">
					<div className="title f-20">{allCompaniesButtonText}</div>
					<Button variant="secondary" size="sm" onClick={() => props.history.push('/company')}>
						All Companies
					</Button>
				</div>
				<div className="flex-x flex-1 align-center custom-dropdown-button">
					<CustomMenu handleChange={handleChange} clearSearchBar={clearSearchBar} value={value}>
						<Dropdown.Item eventKey={0} onClick={() => props.showDashboard(null)}>
							All Companies
						</Dropdown.Item>
						{companiesDropdown &&
							companiesDropdown.length &&
							companiesDropdown.map((company, key) => {
								return (
									<Dropdown.Item eventKey={key + 1} onClick={(e) => handleSearchInput(e, company)}>
										{company.name}
									</Dropdown.Item>
								);
							})}
					</CustomMenu>
				</div>
				<div className="flex-x center">
					<DropdownButton
						variant="secondary"
						title={dropdownButtonTitle}
						id="bg-vertical-dropdown-1"
						size="sm"
						className="custom-dropdown-button"
					>
						<Dropdown.Item
							eventKey="2"
							className={filterKey === 0 ? 'selected' : ''}
							onClick={() => handleDropdownButtonSelect('All Time', 0)}
						>
							All Time
						</Dropdown.Item>
						<Dropdown.Item
							eventKey="2"
							className={filterKey === 7 ? 'selected' : ''}
							onClick={() => handleDropdownButtonSelect('Last 7 days', 7)}
						>
							Last 7 days
						</Dropdown.Item>
						<Dropdown.Item
							eventKey="3"
							className={filterKey === 30 ? 'selected' : ''}
							onClick={() => handleDropdownButtonSelect('Last 30 days', 30)}
						>
							Last 30 days
						</Dropdown.Item>
						<Dropdown.Item
							eventKey="4"
							className={filterKey === 90 ? 'selected' : ''}
							onClick={() => handleDropdownButtonSelect('Last 90 days', 90)}
						>
							Last 90 days
						</Dropdown.Item>
						<div
							className={filterKey === -1 ? 'custom-menu-pad selected' : 'custom-menu-pad'}
							onClick={() => handleDropdownButtonSelect('Custom', -1)}
						>
							Custom
						</div>
						<div className={filterKey === -1 ? 'showCalender show' : 'showCalender hide'}>
							<DatePicker
								selected={startDate}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								onChange={(e) => handleChangeStart(e)}
								maxDate={new Date()}
							/>
							<DatePicker
								selected={endDate}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								onChange={(e) => handleChangeEnd(e)}
								minDate={startDate}
								maxDate={new Date()}
							/>
							<Button variant="primary" size="sm" onClick={() => filterDataCustomDateWise()}>
								Apply
							</Button>
						</div>
					</DropdownButton>

					<DropdownButton
						variant="secondary"
						title="Export"
						id="bg-vertical-dropdown-1"
						size="sm"
						className="custom-dropdown-button"
					>
						<CSVLink data={csvData} target="_blank" className="dropdown-item">
							CSV
						</CSVLink>
						<Dropdown.Item eventKey="2" onClick={exportToPNG}>
							PNG
						</Dropdown.Item>
					</DropdownButton>
					<Button variant="primary" size="sm" onClick={() => handleRefreshData()}>
						REFRESH DATA &nbsp;<i class="fas fa-sync-alt" />
					</Button>
				</div>
			</div>
			<div className="flex-x p-2">
				<Card style={{ width: '100%' }}>
					<Card.Body>
						{companydata && companydata.length ? (
							<Row>
								{companydata.map((col, key) => {
									return (
										<Column
											checked={columnCheck === col.id}
											col={col}
											redirectToCompany={redirectToCompany}
											redirectToUsers={redirectToUsers}
											onChecked={(arg) => handleChecked(col.id, arg)}
										/>
									);
								})}
							</Row>
						) : (
							<div className="d-flex justify-content-center">
								<div
									className="spinner-border text-primary m-5"
									role="status"
									style={{ width: '4rem', height: '4rem' }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						)}
					</Card.Body>
				</Card>
			</div>
			<div className="flex-x p-2">
				<Card style={{ width: '100%' }}>
					<Card.Body>
						<p className="title" style={{ color: colors[0], textAlign: 'center' }}>
							{title}
						</p>
						<div>
							{lineData ? (
								<Line id="myChart" data={lineData} width={100} height={40} options={lineOptions} />
							) : (
								<div className="d-flex justify-content-center">
									<div
										className="spinner-border text-primary m-5"
										role="status"
										style={{ width: '4rem', height: '4rem' }}
									>
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							)}
						</div>
					</Card.Body>
				</Card>
			</div>
			<div className="flex-x p-2">
				<Card style={{ width: '100%' }}>
					<Card.Body>
						{pieData ? (
							<Doughnut data={pieData} />
						) : (
							<div className="d-flex justify-content-center">
								<div
									className="spinner-border text-primary m-5"
									role="status"
									style={{ width: '4rem', height: '4rem' }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						)}
					</Card.Body>
				</Card>
				<Card style={{ width: '100%' }}>
					<Card.Body>
						{areaData ? (
							<Line id="myChart" data={areaData} options={areaOptions} />
						) : (
							<div className="d-flex justify-content-center">
								<div
									className="spinner-border text-primary m-5"
									role="status"
									style={{ width: '4rem', height: '4rem' }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						)}
					</Card.Body>
				</Card>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			isLogin: state.auth.isLogin,
			accessToken: state.auth.accessToken,
			authUser: state.auth.authUser
		},
		company: state.dashboard.showDashboard
	};
};

export default compose(
	withRouter,
	connect(mapStateToProps, { showDashboard, selectedRecipientsButton, selectUsers, selectOption })
)(React.memo(Dashboard));

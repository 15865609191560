const selectCustomUsersActions = {
	SELECTED_USERS: 'SELECTED_USERS',
	SELECTED_USERS_ID: 'SELECTED_USERS_ID',

	selectUsers: (users) => {
		return {
			type: selectCustomUsersActions.SELECTED_USERS,
			users: users
		};
	},

	selectUsersId: (users_id) => {
		return {
			type: selectCustomUsersActions.SELECTED_USERS_ID,
			users_id: users_id
		};
	}
};

export default selectCustomUsersActions;

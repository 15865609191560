import React from 'react';
import { Dropdown } from 'react-bootstrap';

const userPanel = (props) => {
	return (
		<div className="user-panel">
			<Dropdown className="flex-x align-center">
				<Dropdown.Toggle variant="none" id="dropdown-basic" className="flex-0">
					<img src={props.source} alt="User-Profile" className="user-img-sm" />
				</Dropdown.Toggle>
				<p className={props.class}>{props.user.name}</p>
				<Dropdown.Menu>
					<Dropdown.Item onClick={props.clicked}>Profile/Password</Dropdown.Item>
					{/* <Dropdown.Item href="#/action-2">Admin Management</Dropdown.Item> */}
					<Dropdown.Item onClick={props.logout}>Logout</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default userPanel;

const columnToggleListActions = {
	SELECTED_COLUMNS: 'SELECTED_COLUMNS',
	SELECTED_USERCOLUMNS: 'SELECTED_USERCOLUMNS',

	selectColumn: (columns) => {
		return {
			type: columnToggleListActions.SELECTED_COLUMNS,
			columns: columns
		};
	},

	selectUserColumn: (userColumns) => {
		return {
			type: columnToggleListActions.SELECTED_USERCOLUMNS,
			userColumns: userColumns
		};
	}
};

export default columnToggleListActions;

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import logo from 'logo-vabotu.svg';
import AuthActions from 'redux/auth/actions';
import API from '../../api';

const { login } = AuthActions;

const LoginLayout = (props) => {
	const [ credentials, setCredentials ] = useState({ username: '', password: '' });
	const [ error, setError ] = useState('');

	const inputHandler = (input, event) => {
		let state = Object.assign({}, credentials);
		state[input] = event.target.value;
		setCredentials(state);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		var axios = require('axios');
		axios
			.request({
				url: '/oauth/token',
				method: 'post',
				baseURL: process.env.REACT_APP_REST_API,
				data: {
					username: credentials.username,
					password: credentials.password,
					client_id: process.env.REACT_APP_CLIENT_ID,
					client_secret: process.env.REACT_APP_CLIENT_SECRET,
					grant_type: 'password',
					scope: '*'
				}
			})
			.then((res) => {
				const data = {
					token: 'Bearer ' + res.data.access_token
				};

				const encodeForm = (data) => {
					return Object.keys(data)
						.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
						.join('&');
				};
				const userData = {
					action: 'login'
				};
				API.post('/actionLog', encodeForm(userData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: 'Bearer ' + res.data.access_token
					}
				}).then((response) => {
					props.login(data);
					props.history.push('/home');
				});
			})
			.catch((err) => {
				setError('Incorrect Credentials');
			});
	};

	return (
		<div className="wrapper bg-vabotu-gradient">
			<div className="login-box">
				<div className="login-box-body">
					<div className="login-logo">
						<span className="logo-lg">
							<img src={logo} width="200" height="41" alt="Vabotu" />
						</span>
					</div>
					<h6 className="login-box-msg">Sign in to start your session</h6>
					<div className="card-body">
						<span className="error">{error}</span>
						<Form onSubmit={(e) => handleSubmit(e)}>
							<Form.Control
								type="text"
								className="form-control-lg mb-2"
								onChange={(e) => inputHandler('username', e)}
								placeholder="Email"
								required
							/>
							<Form.Control
								type="password"
								className="form-control-lg mb-2"
								onChange={(e) => inputHandler('password', e)}
								placeholder="Password"
								required
							/>
							<div className="flex-x center">
								<div className="flex-0">
									<Button variant="success" type="submit">
										Login
									</Button>
								</div>
							</div>
							{/* <div>Forgot your Password?</div> */}
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default compose(withRouter, connect(null, { login }))(LoginLayout);

import React, { useState, useEffect } from 'react';
import { Card, DropdownButton, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import 'react-datepicker/dist/react-datepicker.css';
import CustomToggleList from '../common/columnToggle';
import ColumnToggleActions from 'redux/columnToggle/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const { SearchBar, ClearSearchButton } = Search;
const { selectUserColumn } = ColumnToggleActions;
const { ExportCSVButton } = CSVExport;

const header = (props) => {
	const [ searchInput, setSearchInput ] = useState(null);
	const [ columnToggleList, setColumnToggleList ] = useState([]);

	useEffect(
		() => {
			if (props.userColumns) {
				setColumnToggleList(props.userColumns);
			}
		},
		[ props.userColumns ]
	);

	useEffect(
		() => {
			// let cols = props.columnToggleProps.columns;
			// cols.forEach((column) => {
			// 	if (columnToggleList && columnToggleList.length && columnToggleList.includes(column.dataField)) {
			// 		props.columnToggleProps.toggles[column.dataField] = true;
			// 	} else {
			// 		props.columnToggleProps.toggles[column.dataField] = false;
			// 	}
			// });
		},
		[ columnToggleList ]
	);

	const handleColumnToggleList = (column) => {
		let columnList = columnToggleList ? columnToggleList : [];
		if (columnList.includes(column)) {
			const index = columnList.indexOf(column);
			setColumnToggleList((columnList) => {
				const selectedvalues = columnList;
				selectedvalues.splice(index, 1);
				props.selectUserColumn(selectedvalues);
				return [ ...selectedvalues ];
			});
		} else {
			setColumnToggleList((columnList) => {
				props.selectUserColumn([ ...columnList, column ]);
				return [ ...columnList, column ];
			});
		}
	};

	const clearSearchBar = () => {
		setSearchInput(null);
	};

	return (
		<Card style={{ width: '100%' }}>
			<Card.Body style={{ padding: '0' }}>
				<div className="flex-x p-2">
					<div className="flex-x">
						<div className="flex-x center text-black">
							Show
							<SizePerPageDropdownStandalone
								{...props.paginationProps}
								btnContextual="none"
								className="custom-dropdown-button"
							/>
							entries
						</div>
						<DropdownButton variant="light" title="Column visibility" size="sm">
							<CustomToggleList
								{...props.columnToggleProps}
								contextual="none"
								columnToggle={(column) => handleColumnToggleList(column)}
								className="btn-group-vertical sm toggler"
							/>
						</DropdownButton>
					</div>
					<div className="flex-x center flex-1">
						<ButtonGroup size="sm">
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>All users(active, inactive, admin, owner)</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'all' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.setSelectedButton('all')}
									disabled={props.counts ? props.counts.total === 0 ? true : false : true}
								>
									All&nbsp;<span>{props.counts ? props.counts.total : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Admin and Owner of the company (includes active and inactive)
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'admins' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.setSelectedButton('admins')}
									disabled={props.counts ? props.counts.admins === 0 ? true : false : true}
								>
									Admins&nbsp;<span>{props.counts ? props.counts.admins : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Owner of the company (includes active and inactive)
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'owners' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.setSelectedButton('owners')}
									disabled={props.counts ? props.counts.owners === 0 ? true : false : true}
								>
									Owners&nbsp;<span>{props.counts ? props.counts.owners : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={<Tooltip id={`tooltip-top`}>Active users from active company</Tooltip>}
							>
								<Button
									variant={props.selectedButton === 'active' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.setSelectedButton('active')}
									disabled={props.counts ? props.counts.active === 0 ? true : false : true}
								>
									Active&nbsp;<span>{props.counts ? props.counts.active : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>Inactive users and users of inactive company</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'inactive' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.setSelectedButton('inactive')}
									disabled={props.counts ? props.counts.inactive === 0 ? true : false : true}
								>
									Inactive&nbsp;<span>{props.counts ? props.counts.inactive : 0}</span>
								</Button>
							</OverlayTrigger>
						</ButtonGroup>
					</div>
					<div className="flex-x center custom-dropdown-button">
						<SearchBar
							{...props.searchProps}
							placeholder="&#xF002; Filter Users"
							className="search-box fontAwsome"
							onChange={(e) => setSearchInput(e.target.value)}
							value={searchInput}
						/>
						<ClearSearchButton
							className="bg-transparent clear-button fa fa-times"
							{...props.searchProps}
							onClick={clearSearchBar}
							text=""
						>
							<i className="fa fa-times" />
						</ClearSearchButton>
						<ExportCSVButton {...props.csvProps} className="btn btn-secondary" size="sm">
							Export
						</ExportCSVButton>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		userColumns: state.columnToggle && state.columnToggle.userColumns,
		userCompany: state.usersList.showUserList
	};
};

export default compose(withRouter, connect(mapStateToProps, { selectUserColumn }))(header);

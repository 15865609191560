import React, { useState, useEffect } from 'react';
import Table from 'components/table/table';
import Header from 'components/userList/header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import API from '../../api';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Switch } from 'pretty-checkbox-react';
import ColumnToggleActions from 'redux/columnToggle/actions';
import ShowDashboardActions from 'redux/dashboard/actions';

const { showDashboard, selectOption } = ShowDashboardActions;
const { selectColumn } = ColumnToggleActions;

const ListUsers = (props) => {
	const [ userData, setUserData ] = useState(null);
	const [ selectedButton, setSelectedButton ] = useState('all');
	const [ counts, setCounts ] = useState(null);

	let filteredData = null;

	const linkFormatter = (cell) => {
		return (
			<a href={'mailto:' + cell} className="table-link word-break">
				{cell}
			</a>
		);
	};

	let commonColumns = [
		{
			dataField: 'key',
			text: 'Index',
			sort: true
		},
		{
			dataField: 'first',
			text: 'First Name',
			sort: true
		},
		{
			dataField: 'last',
			text: 'Last Name',
			sort: true
		},
		{
			dataField: 'email',
			text: 'Email',
			sort: true,
			formatter: linkFormatter
		},
		{
			dataField: 'company_name',
			text: 'Company Name',
			sort: true,
			formatter: (value, row) => {
				return (
					<div className="table-link word-break" onClick={() => redirectDashboard(row)}>
						{row.company_name}
					</div>
				);
			}
		},
		{
			dataField: 'role',
			text: 'Role',
			sort: true
		},
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
			csvExport: true,
			formatter: (value, row) => {
				if (row.id) {
					return (
						<div>
							<Switch
								type="checkbox"
								color="success"
								shape="fill"
								checked={row.status === 'active' ? true : false}
								onChange={() => openChangeStatusConfirmAlert(row)}
							/>
						</div>
					);
				}
			}
		},
		{
			dataField: 'designation',
			text: 'Designation',
			sort: true
		},
		{
			dataField: 'phone',
			text: 'Phone',
			sort: true,
			formatter: (value, row) => {
				if (row.id) {
					return <div>{row.last_active}</div>;
				}
			}
		},
		{
			dataField: 'skype',
			text: 'Skype',
			sort: true
		},
		{
			dataField: 'country',
			text: 'Country',
			sort: true
		},
		{
			dataField: 'city',
			text: 'City',
			sort: true
		}
	];

	var columns = commonColumns;

	useEffect(() => {
		props.showDashboard(null);

		if (props.dashboard.selectedOption && props.dashboard.selectedOption === 'Active Users') {
			setSelectedButton('active');
		} else {
			setSelectedButton('all');
		}
	}, []);

	useEffect(
		() => {
			if (props.userCompany && props.userCompany.id) {
				API.get('/viewusers/' + props.userCompany.id, {
					headers: { Authorization: props.authData.accessToken }
				})
					.then(async (res) => {
						let d = res.data.data.userdata;
						setCounts({
							total: res.data.data.total_users,
							owners: res.data.data.total_owners,
							active: res.data.data.total_active,
							inactive: res.data.data.total_inactive,
							admins: res.data.data.total_admins
						});
						columns = commonColumns;
						setUserData(d);
					})
					.catch((err) => err);
			} else {
				API.get('/viewusers', { headers: { Authorization: props.authData.accessToken } })
					.then(async (res) => {
						let d = res.data.data.userdata;
						setCounts({
							total: res.data.data.total_users,
							owners: res.data.data.total_owners,
							active: res.data.data.total_active,
							inactive: res.data.data.total_inactive,
							admins: res.data.data.total_admins
						});
						columns = commonColumns;
						setUserData(d);
					})
					.catch((err) => err);
			}
		},
		[ props.company ]
	);

	if (selectedButton === 'admins') {
		filteredData =
			userData &&
			userData.length &&
			userData.filter((user, key) => {
				if (user.role === 'Admin' || user.role === 'Owner') {
					return user;
				}
				return null;
			});
	} else if (selectedButton === 'owners') {
		filteredData =
			userData &&
			userData.length &&
			userData.filter((user, key) => {
				if (user.role === 'Owner') {
					return user;
				}
				return null;
			});
	} else if (selectedButton === 'active') {
		filteredData =
			userData &&
			userData.length &&
			userData.filter((user, key) => {
				if (user.status === 'active') {
					return user;
				}
				return null;
			});
	} else if (selectedButton === 'inactive') {
		filteredData =
			userData &&
			userData.length &&
			userData.filter((user, key) => {
				if (user.status === 'inactive') {
					return user;
				}
				return null;
			});
	} else {
		filteredData = userData;
	}

	// const rowStyle = (row, rowIndex) => {
	// 	const style = {};
	// 	if (row.role === 'Admin') {
	// 		style.backgroundColor = 'rgba(0, 0, 0, 0.075)';
	// 	}
	// 	return style;
	// };

	const defaultSorted = [
		{
			dataField: 'id',
			order: 'asc'
		}
	];

	let options;

	const openChangeStatusConfirmAlert = (row) => {
		var status = row.status === 'active' ? 'Inactivate' : 'Activate';
		confirmAlert({
			title: `Confirm ${status} User`,
			message: 'Are you sure to ' + status + ' this User.',
			buttons: [
				{
					label: status,
					onClick: () => userStatusHandler(row)
				},
				{
					label: 'Cancel'
				}
			]
		});
	};

	const userStatusHandler = (user) => {
		let formData = { id: user.id, status: user.status };

		const encodeForm = (data) => {
			return Object.keys(data)
				.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		};

		API.post('/viewusers/change/status/', encodeForm(formData), {
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*',
				Authorization: props.authData.accessToken
			}
		})
			.then((res) => {
				setUserData((userData) => {
					return [
						...userData,
						...userData.map((data) => {
							if (data && data.id === user.id) {
								if (user.status === 'inactive') return (data.status = 'active');
								else return (data.status = 'inactive');
							}
						})
					];
				});
			})
			.catch((err) => {
				return err;
			});
	};

	const redirectDashboard = (user) => {
		if (user.company_name !== 'All Companies') {
			let comp = { id: user.company_id, company_name: user.company_name };
			props.showDashboard(comp);
		} else {
			props.showDashboard(null);
		}
		props.history.push('/home');
	};

	if (userData && userData.length) {
		options = {
			custom: true,
			totalSize: userData.length,
			sizePerPageList: [
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				},
				{
					text: 'All',
					value: filteredData.length
				}
			]
		};
	}

	return (
		<div className="content justify-content-md-center">
			<h5 className="title">
				{props.userCompany ? props.userCompany && props.userCompany.company_name : 'All Users Data'}
			</h5>
			{filteredData && filteredData.length ? (
				<ToolkitProvider
					bootstrap4
					keyField="key"
					columns={columns}
					data={filteredData}
					defaultSorted={defaultSorted}
					search
					columnToggle
					exportCSV={{
						fileName: new Date() + '_users.csv',
						exportAll: false,
						onlyExportFiltered: false
					}}
				>
					{(props) => (
						<PaginationProvider pagination={paginationFactory(options)}>
							{({ paginationProps, paginationTableProps }) => (
								<div className="p-2">
									<Header
										{...props}
										paginationProps={paginationProps}
										columnToggleProps={props.columnToggleProps}
										searchProps={props.searchProps}
										setSelectedButton={setSelectedButton}
										selectedButton={selectedButton}
										counts={counts}
									/>
									<div className="flex-x flex-0 list-flex p-2">
										<PaginationListStandalone {...paginationProps} />
									</div>
									<Table
										striped
										hover
										condensed
										bordered={false}
										// rowStyle={rowStyle}
										{...paginationTableProps}
										{...props.baseProps}
									/>
								</div>
							)}
						</PaginationProvider>
					)}
				</ToolkitProvider>
			) : (
				<div className="d-flex justify-content-center">
					<div
						className="spinner-border text-primary m-5"
						role="status"
						style={{ width: '4rem', height: '4rem' }}
					>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			isLogin: state.auth.isLogin,
			accessToken: state.auth.accessToken
		},
		userCompany: state.usersList.showUserList,
		selectedColumns: state.columnToggle && state.columnToggle.columns,
		dashboard: {
			selectedOption: state.dashboard.selectOption
		}
	};
};

export default compose(withRouter, connect(mapStateToProps, { selectColumn, showDashboard, selectOption }))(ListUsers);

import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Button, Table, Pagination } from 'react-bootstrap';
import { Checkbox } from 'pretty-checkbox-react';
import TableRow from 'components/message/custom/tableRow';
import CustomSelectedUserAction from 'redux/customMessage/actions';
import API from '../../api';

const { selectUsers, selectUsersId } = CustomSelectedUserAction;

const Custom = (props) => {
	const [ selected, setSelected ] = useState([]);
	const [ selectedUserIds, setSelectedUserIds ] = useState([]);
	const [ selectedRootChecks, setSelectedRootChecks ] = useState([]);
	const [ selectedUserCountChecks, setSelectedUserCountChecks ] = useState([]);
	const [ selectedCompanyChecks, setSelectedCompanyChecks ] = useState([]);
	const [ data, setData ] = useState([]);
	const [ filteredData, setFilteredData ] = useState(data);
	const [ ownerCount, setOwnerCount ] = useState(0);
	const [ userCount, setUserCount ] = useState(0);
	const [ duration, setDuration ] = useState(null);
	const [ type, setType ] = useState('day');
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ dataPerPage, setDataPerPage ] = useState(50);
	const [ totalPage, setTotalPage ] = useState(0);

	let pages = [];

	useEffect(() => {
		API.get('/message/custom/users', { headers: { Authorization: props.authData.accessToken } })
			.then((res) => {
				setData(res.data.data.company_data);
				setUserCount(res.data.data.user_count);
				setOwnerCount(res.data.data.owner_count);
			})
			.catch((err) => err);
		if (props.selectedRecipients) {
			setSelected(() => props.selectedRecipients);
		}

		if (props.selectedRecipientsId) {
			setSelectedUserIds(() => props.selectedRecipientsId);
		}

		const indexOfLastTodo = currentPage * dataPerPage;
		const indexOfFirstTodo = indexOfLastTodo - dataPerPage;
		const pagedData = data.slice(indexOfFirstTodo, indexOfLastTodo);
		setFilteredData(() => pagedData);
	}, []);

	useEffect(
		() => {
			const indexOfLastTodo = currentPage * dataPerPage;
			const indexOfFirstTodo = indexOfLastTodo - dataPerPage;
			const pagedData = data.slice(indexOfFirstTodo, indexOfLastTodo);
			setFilteredData(() => pagedData);
		},
		[ currentPage, dataPerPage ]
	);

	useEffect(
		() => {
			setTotalPage(Math.ceil(data.length / dataPerPage));
			const indexOfLastTodo = currentPage * dataPerPage;
			const indexOfFirstTodo = indexOfLastTodo - dataPerPage;
			const pagedData = data.slice(indexOfFirstTodo, indexOfLastTodo);
			setFilteredData(() => pagedData);
		},
		[ data ]
	);

	const handleChecked = (e,user) => {
		let selectedValues = selected;
		let selectedUserIdValues = selectedUserIds;
		// let user = user;
		let value = e.target.value;
		let userID = user.user_id ? user.user_id : user.id;

		if (selectedValues.includes(value)) {
			const index = selected.indexOf(value);
			setSelected((selected) => {
				const selectedvalues = selected;
				selectedvalues.splice(index, 1);
				props.selectUsers(selectedValues);
				return [ ...selectedvalues ];
			});
		} else {
			setSelected((selected) => {
				props.selectUsers([ ...selected, value ]);
				return [ ...selected, value ];
			});
		}

		if (selectedUserIdValues.includes(userID)) {
			const index = selectedUserIds.indexOf(userID);
			setSelectedUserIds((selectedUserIds) => {
				const selectedUserIdvalues = selectedUserIds;
				selectedUserIdvalues.splice(index, 1);
				props.selectUsersId(selectedValues);
				return [ ...selectedUserIdvalues ];
			});
		} else {
			setSelectedUserIds((selectedUserIds) => {
				props.selectUsersId([ ...selectedUserIds, userID ]);
				return [ ...selectedUserIds, userID ];
			});
		}
	};

	const handleAllUsers = (element, users) => {
		console.log('handleAllUsers')

		let value = element.target.value;
		if (users && users.length) {
			if (!selectedUserCountChecks.includes(value)) {
				props.selectUsers([ ...selected, ...users.map((a) => a.email) ]);
				props.selectUsersId([ ...selected, ...users.map((a) => a.user_id) ]);
				setSelected((selected) => {
					props.selectUsers([ ...selected, ...users.map((a) => a.email) ]);
					return [ ...selected, ...users.map((a) => a.email) ];
				});
				setSelectedUserIds((selectedUserId) => {
					props.selectUsersId([ ...selectedUserId, ...users.map((a) => a.user_id) ]);
					return [ ...selectedUserId, ...users.map((a) => a.user_user_id) ];
				});

				setSelectedUserCountChecks((selectedUserCountChecks) => [ ...selectedUserCountChecks, value ]);
			} else {
				const index = selectedUserCountChecks.indexOf(value);
				setSelectedUserCountChecks((selectedUserCountChecks) => {
					const selectedvalues = selectedUserCountChecks;
					selectedvalues.splice(index, 1);
					return [ ...selectedvalues ];
				});
				props.selectUsers([]);
				props.selectUsersId([]);
				setSelected((selected) => []);
				setSelectedUserIds((selected) => []);
			}
		}
	};

	const handleAllUsersChecked = (element) => {
		console.log('handleAllUsersChecked')
		let value1 = Number(element.target.value);
		if (data && data.length) {
			data.forEach((d) => {
				let value = d.user_id + '' + d.users.length;
				if (!selectedUserCountChecks.includes(value)) {
					setSelectedUserCountChecks((selectedUserCountChecks) => [
						...selectedUserCountChecks,
						d.user_id + '' + d.users.length
					]);
					
					props.selectUsers([ ...selected, ...d.users.map((a) => a.email) ]);
					props.selectUsersId([ ...selected, ...d.users.map((a) => a.user_id) ]);
					
					setSelected((selected) => {
						props.selectUsers([ ...selected, ...d.users.map((a) => a.email) ]);
						return [ ...selected, ...d.users.map((a) => a.email) ];
					});
					setSelectedUserIds((selectedUserId) => {
						props.selectUsersId([ ...selectedUserId, ...d.users.map((a) => a.user_id) ]);
						return [ ...selectedUserId, ...d.users.map((a) => a.user_id) ];
					});
				} else {
					setSelectedUserCountChecks(() => []);
					props.selectUsers([]);
					props.selectUsersId([]);
					setSelected((selected) => []);
					setSelectedUserIds((selectedUserId) => []);
				}
			});
		}
		if (!selectedRootChecks.includes(value1)) {
			setSelectedRootChecks([ ...selectedRootChecks, value1 ]);
		} else {
			const index = selectedRootChecks.indexOf(value1);
			setSelectedRootChecks((selectedRootChecks) => {
				const selectedvalues = selectedRootChecks;
				selectedvalues.splice(index, 1);
				return [ ...selectedvalues ];
			});
		}
	};

	const handleSingleCompanyChecked = (element) => {
		console.log('handleSingleCompanyChecked')
		let value = Number(element.target.value);
		if (data && data.length) {
			if (!selectedCompanyChecks.includes(value)) {
				data.forEach((d) => {
					if (value === d.id) {
						setSelected((selected) => {
							props.selectUsers([ ...selected, ...d.users.map((u) => u.email) ]);
							return [ ...selected, ...d.users.map((u) => u.email) ];
						});
						setSelectedUserIds((selectedUserId) => {
							props.selectUsersId([ ...selectedUserId, ...d.users.map((u) => u.user_id) ]);
							return [ ...selectedUserId, ...d.users.map((u) => u.user_id) ];
						});
						setSelectedUserCountChecks((selectedUserCountChecks) => [
							...selectedUserCountChecks,
							d.id + '' + d.users.length
						]);
					}
				});
				setSelectedCompanyChecks((selectedCompanyChecks) => [ ...selectedCompanyChecks, value ]);
			} else {
				const index = selectedCompanyChecks.indexOf(value);
				setSelectedCompanyChecks((selectedCompanyChecks) => {
					const selectedvalues = selectedCompanyChecks;
					selectedvalues.splice(index, 1);
					return [ ...selectedvalues ];
				});
				props.selectUsers([]);
				props.selectUsersId([]);
				setSelected((selected) => []);
				setSelectedUserIds((selectedUserId) => []);
			}
		}
	};

	const handleAllAdminsChecked = (element) => {
		console.log('handleAllAdminsChecked')
		let value = Number(element.target.value);
		if (data && data.length) {
			if (!selectedRootChecks.includes(value)) {
				props.selectUsers([ ...selected, ...data.map((d) => d.owners[0].email) ]);
				props.selectUsersId([ ...selected, ...data.map((d) => d.owners[0].id) ]);
				setSelected((selected) => {
					props.selectUsers([ ...selected, ...data.map((d) => d.owners[0].email) ]);
					return [ ...selected, ...data.map((d) => d.owners[0].email) ];
				});
				setSelectedUserIds((selectedUserId) => {
					props.selectUsersId([ ...selectedUserId, ...data.map((d) => d.owners[0].id) ]);
					return [ ...selectedUserId, ...data.map((d) => d.owners[0].id) ];
				});
				props.selectUsers(selected);
				props.selectUsersId(selectedUserIds);
				setSelectedRootChecks((selectedRootChecks) => [ ...selectedRootChecks, value ]);
			} else {
				const index = selectedRootChecks.indexOf(value);
				setSelectedRootChecks((selectedRootChecks) => {
					const selectedvalues = selectedRootChecks;
					selectedvalues.splice(index, 1);
					return [ ...selectedvalues ];
				});
				setSelectedUserCountChecks((selectedUserCountChecks) => []);
				props.selectUsers([]);
				props.selectUsersId([]);
				setSelected((selected) => []);
				setSelectedUserIds((selectedUserId) => []);
			}
		}
	};

	const handleAllCompaniesChecked = (element) => {
		console.log('handleAllCompaniesChecked')
		let value = Number(element.target.value);
		if (data && data.length) {
			if (!selectedRootChecks.includes(value)) {
				setSelectedRootChecks(() => [ ...selectedRootChecks, value ]);
				data.forEach((d, index) => {
					setSelected((selected) => {
						props.selectUsers([ ...selected, ...d.users.map((u) => u.email) ]);
						return [ ...selected, ...d.users.map((u) => u.email) ];
					});
					setSelectedUserIds((selectedUserId) => {
						props.selectUsersId([ ...selectedUserId, ...d.users.map((u) => u.user_id) ]);
						return [ ...selectedUserId, ...d.users.map((u) => u.user_id) ];
					});

					if (!selected.includes(d.owners[0].email)) {
						setSelected((selected) => {
							props.selectUsers([ ...selected, d.owners[0].email ]);
							return [ ...selected, d.owners[0].email ];
						});
					}
					if (!selectedUserIds.includes(d.owners[0].id)) {
						setSelectedUserIds((selectedUserId) => {
							props.selectUsersId([ ...selectedUserId, d.owners[0].id ]);
							return [ ...selectedUserId, d.owners[0].id ];
						});
					}

					setSelectedCompanyChecks((selectedCompanyChecks) => [ ...selectedCompanyChecks, d.id ]);
					setSelectedUserCountChecks((selectedUserCountChecks) => [
						...selectedUserCountChecks,
						d.id + '' + d.users.length
					]);
				});
			} else {
				props.selectUsers([]);
				props.selectUsersId([]);
				setSelected(() => []);
				setSelectedUserIds(() => []);
				setSelectedRootChecks(() => []);
				setSelectedUserCountChecks(() => []);
				setSelectedCompanyChecks(() => []);
			}
		}
	};

	// const changeDaysHandler = (event) => {
	// 	setDuration(event.target.value);
	// };

	const handleGetCompany = () => {
		let formData = { duration: duration, type: type };
		const encodeForm = (data) => {
			return Object.keys(data)
				.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		};

		API.post('/message/companywise/days', encodeForm(formData), {
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*',
				Authorization: props.authData.accessToken
			}
		})
			.then((res) => {
				setData(res.data.data.company_data);
				setFilteredData(res.data.data.company_data);
				setUserCount(res.data.data.user_count);
				setOwnerCount(res.data.data.owner_count);
			})
			.catch((err) => err);
	};

	const filterDataHandler = (event) => {
		var searchTerm = event.target.value;
		let searchData = data.filter((d) => {
			let adminName = d.owners[0].first + ' ' + d.owners[0].last;
			if (d.name.toLowerCase().includes(searchTerm.toLowerCase())) return d;
			if (adminName.toLowerCase().includes(searchTerm.toLowerCase())) return d;
			var users = d.users.filter((user) => {
				let userName = user.first + ' ' + user.last;
				if (userName.toLowerCase().includes(searchTerm.toLowerCase())) return user;
				return null;
			});
			if (users.length > 0) return d;
			return null;
		});
		if (filteredData.length > 0) {
			setFilteredData(() => searchData);
		} else {
			setFilteredData(() => data);
		}
	};

	const handleCancelClick = () => {
		setSelected(() => []);
		setSelectedUserIds(() => []);
		setSelectedRootChecks(() => []);
		setSelectedCompanyChecks(() => []);
		setSelectedUserCountChecks(() => []);
		// props.selectUsers([]);
		props.history.push('/messages');
	};

	for (let i = 1; i <= totalPage; i++) {
		pages.push(
			<Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
				{i}
			</Pagination.Item>
		);
	}

	return (
		<div className="content justify-content-md-center">
			<div className="flex-x">
				<div className="flex-1">
					<div className="title f-20">
						Select Recipients:
						<span className="bold">{selected.length}</span>
					</div>
				</div>
				<div className="flex-x">
					<div className="flex-x">
						<Button variant="none" size="sm" onClick={handleCancelClick}>
							Cancel
						</Button>
						<Button
							variant="none"
							size="sm"
							style={{ backgroundColor: '#2F2456', color: 'white' }}
							onClick={() => props.history.push('/messages')}
						>
							Done
						</Button>
					</div>
				</div>
			</div>
			<div className="flex-1 p-2">
				<Card>
					<Card.Body style={{ padding: 0 }}>
						<div className="flex-x align-center p-2">
							<span className="f-12 demi-bold text-black">Last Active &nbsp;</span>
							<input
								type="text"
								className="form-control day-box"
								onChange={(e) => setDuration(e.target.value)}
							/>&nbsp;
							{/* &nbsp; <span className="f-12 demi-bold text-black">days ago &nbsp;</span> */}
							<select
								className="form-control"
								style={{ width: '10%' }}
								onChange={(e) => setType(e.target.value)}
							>
								<option value="day">Day</option>
								<option value="week">Week</option>
								<option value="month">Month</option>
							</select>
							&nbsp; ago &nbsp;
							<div className="flex-1">
								<Button
									variant="success"
									size="sm"
									onClick={() => handleGetCompany()}
									disabled={duration ? false : true}
								>
									Get Companies
								</Button>
							</div>
							<div className="flex-x">
								<input
									type="text"
									placeholder="&#xF002; Filter Companies, Admins or Users"
									className="form-control search-box w-250"
									onChange={(e) => filterDataHandler(e)}
								/>
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>
			<div className="flex-1 p-2">
				<Table striped responsive>
					<thead>
						<tr className="p-2">
							<th className="th demi-bold">
								Company Wise <i className="fas fa-sort-amount-down" />
							</th>
							<th className="th demi-bold">
								Admin <i className="fas fa-sort-amount-down" />
							</th>
							<th className="th demi-bold">
								Users <i className="fas fa-sort-amount-down" />
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className="p-2">
							<td>
								<Checkbox
									animation="smooth"
									shape="round"
									value={1}
									color="primary-o"
									checked={selectedRootChecks.includes(1)}
									onChange={(e) => handleAllCompaniesChecked(e)}
								/>
								<label className="form-check-label title">ALL COMPANIES</label>
							</td>
							<td>
								<label className="form-check-label title">
									<Checkbox
										animation="smooth"
										shape="round"
										value={2}
										color="primary-o"
										checked={selectedRootChecks.includes(2)}
										onChange={(e) => handleAllAdminsChecked(e)}
									>
										<label className="form-check-label title">
											All Admins &nbsp;<span className="count">{ownerCount}</span>
										</label>
									</Checkbox>
								</label>
							</td>
							<td>
								<Checkbox
									animation="smooth"
									shape="round"
									value={3}
									color="primary-o"
									checked={selectedRootChecks.includes(3)}
									onChange={(e) => handleAllUsersChecked(e)}
								>
									<label className="form-check-label title">
										All users &nbsp;<span className="count">{userCount}</span>
									</label>
								</Checkbox>
							</td>
						</tr>
						{filteredData.length &&
							filteredData.map((d, key) => {
								return (
									<TableRow
										companyName={d.name}
										companyID={d.id}
										adminName={d.owners}
										users={d.users}
										key={key}
										id={key}
										handleChecked={handleChecked}
										selected={selected}
										selectedRootChecks={selectedRootChecks}
										selectedUserCountChecks={selectedUserCountChecks}
										handleAllUsers={handleAllUsers}
										selectedCompanyChecks={selectedCompanyChecks}
										handleSingleCompanyChecked={handleSingleCompanyChecked}
									/>
								);
							})}
					</tbody>
				</Table>
				<div className="flex-x text-black">
					<div className="flex-1">
						<span className="f-12 demi-bold">
							Show
							<select
								className="custom-select-sm p-2 m-1"
								onChange={(e) => setDataPerPage(e.target.value)}
								value={dataPerPage}
							>
								<option value={dataPerPage}>50</option>
								<option value={100}>100</option>
								<option value={data.length}>All</option>
							</select>
							entries
						</span>
					</div>
					<div className="flex-x">
						<Pagination>
							<Pagination.First
								key={1}
								onClick={() => setCurrentPage(1)}
								disabled={currentPage === 1 ? true : false}
							/>
							<Pagination.Prev
								onClick={() => setCurrentPage(currentPage - 1)}
								disabled={currentPage === 1 ? true : false}
							/>
							{pages}
							<Pagination.Next
								disabled={dataPerPage >= data.length ? true : false}
								onClick={() => setCurrentPage(currentPage + 1)}
							/>
							<Pagination.Last
								disabled={dataPerPage >= data.length ? true : false}
								onClick={() => setCurrentPage(data.length / dataPerPage)}
							/>
						</Pagination>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			isLogin: state.auth.isLogin,
			accessToken: state.auth.accessToken,
			authUser: state.auth.authUser
		},
		selectedRecipients: state.customMessage.selectedUsers,
		selectedRecipientsId: state.customMessage.selectedUsersId,
	};
};

export default compose(withRouter, connect(mapStateToProps, { selectUsers, selectUsersId }))(React.memo(Custom));

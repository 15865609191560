import React from 'react';
import { Checkbox } from 'pretty-checkbox-react';

const element = (props) => {
	return (
		<React.Fragment>
			{props.users &&
				props.users.length &&
				props.users.map((user, key) => {
					return (
						<div className="flex-x">
							<div className="flex-y">
								<ul className="list-unstyled">
									<li key={key}>
										<Checkbox
											animation="smooth"
											shape="round"
											color="primary-o"
											value={user.email}
											onChange={(e) => props.checked(e, user && user)}
											checked={props.selected.includes(user.email)}
										>
											<label className="form-check-label">{user.first + ' ' + user.last}</label>
										</Checkbox>
									</li>
								</ul>
							</div>
						</div>
					);
				})}
		</React.Fragment>
	);
};

export default element;

import setRecipientButtonActions from './actions';

const initState = {
	selectedRecipients: null,
	selectedTemplate: [],
	actionButton: null,
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case setRecipientButtonActions.SELECTED_RECIPIENTS_BUTTON:
			return {
				...state,
				selectedRecipients: action.recipientButton
			};
		case setRecipientButtonActions.SELECTED_TEMPLATES:
			return {
				...state,
				selectedTemplate: action.selectedTemplate
			};
		case setRecipientButtonActions.ACTION_BUTTON:
			return {
				...state,
				actionButton: action.actionButton
			};
		default:
			return state;
	}
}

import React from 'react';
import { Checkbox } from 'pretty-checkbox-react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Column = (props) => {
	let elements = props.col.data;
	return (
		<Col>
			<Checkbox
				animation="smooth"
				shape="round"
				color={props.col.checkcolor}
				onChange={() => props.onChecked(props.col)}
				checked={props.checked}
			>
				<label className="form-check-label title" style={{ color: props.col.titlecolor }}>
					{props.col.title}
				</label>
			</Checkbox>
			{elements.map((data, key) => {
				if (props.col.key === 0) {
					return (
						<div key={key} className="pl-3" onClick={() => props.redirectToCompany(data.name)}>
							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={<Tooltip id={`tooltip-right`}>{data.tooltip}</Tooltip>}
							>
								<label className="form-check-label hovered">{data.name}</label>
							</OverlayTrigger>
							<span className="count">{data ? data.count : 0}</span>
							<br />
						</div>
					);
				} else if (props.col.key === 1 && data.id !== 3) {
					return (
						<div key={key} className="pl-3" onClick={() => props.redirectToUsers(data.name)}>
							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={<Tooltip id={`tooltip-right`}>{data.tooltip}</Tooltip>}
							>
								<label className="form-check-label hovered">{data.name}</label>
							</OverlayTrigger>
							<span className="count">{data ? data.count : 0}</span>
							<br />
						</div>
					);
				} else {
					return (
						<div key={key} className="pl-3">
							<label className="form-check-label">{data.name}</label>
							<span className="count">{data ? data.count : 0}</span>
							<br />
						</div>
					);
				}
			})}
		</Col>
	);
};

export default Column;

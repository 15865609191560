import setUserDeailsActions from './actions';

const initState = {
	userImage: null,
	username: null
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case setUserDeailsActions.USERIMAGE:
			return {
				...state,
				userImage: action.userImage
			};
		case setUserDeailsActions.USERNAME:
			return {
				...state,
				username: action.username
			};
		default:
			return state;
	}
}

import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import CKEditor from 'ckeditor4-react';
import API from '../../api';
import RecipientButtonActions from 'redux/message/actions';
import sortBy from 'lodash/sortBy';
import ShowDashboardActions from 'redux/dashboard/actions';
import CustomMessageActions from 'redux/customMessage/actions';
import { confirmAlert } from 'react-confirm-alert';
import socket from '../../socket-cluster/index';

const { selectedRecipientsButton, selectTemplates, selectActionButton } = RecipientButtonActions;
const { showDashboard, selectOption } = ShowDashboardActions;
const { selectUsers, selectUsersId } = CustomMessageActions;

const Messages = (props) => {
	const [ templates, setTemplates ] = useState([]);
	const [ counts, setCounts ] = useState([]);
	const [ selectedTemplate, setSelectedTemplate ] = useState({ id: 0, title: '', subject: '', body: '' });
	const [ btnName, setBtnName ] = useState('');
	const [ responseMessage, setResponseMessage ] = useState('');
	const [ selectedUsers, setSelectedUsers ] = useState('');
	// const [ subject, setSubject ] = useState('');
	const [ error, setError ] = useState('');
	const [ data, setData ] = useState([]);

	useEffect(() => {
		// console.log('props.selectedRecipients', props.selectedRecipients);
		// console.log('props.selectedRecipientsId', props.selectedRecipientsId);
		API.get('/template', { headers: { Authorization: props.authData.accessToken } })
			.then((res) => {
				setTemplates(sortBy(res.data.data, [ 'title' ]));
			})
			.catch((err) => {
				setError(err.message);
			});

		API.get('/template/getCounts', { headers: { Authorization: props.authData.accessToken } })
			.then((res) => {
				setCounts(res.data.data);
			})
			.catch((err) => {
				setError(err.message);
			});
		
		API.get('/message/custom/users', { headers: { Authorization: props.authData.accessToken } })
			.then((res) => {
				setData(res.data.data.company_data);
			})
			.catch((err) => err);

		if (props.recipientsButton) {
			setSelectedUsers(props.recipientsButton);
		}
		if (props.templates) {
			setSelectedTemplate(props.templates);
		}
		if (props.actionButton) {
			setBtnName(props.actionButton);
		} else {
			setBtnName('Save');
			props.selectActionButton('Save');
		}
		if (props.showDashboardFlag !== null) {
			props.showDashboard(null);
		}
		if (props.selectOptionFlag !== null) {
			props.selectOption(null);
		}
	}, []);

	const inputHandler = (input, event) => {
		let state = Object.assign({}, selectedTemplate);
		state[input] = event.target.value;
		setSelectedTemplate(state);
	};

	// const subjectHandler = (event) => {
	// 	let subj = event.target.value;
	// 	setSubject(subj);
	// };

	const editorHandler = (input, event) => {
		let state = Object.assign({}, selectedTemplate);
		state[input] = event.editor.getData();
		setSelectedTemplate(state);
	};

	const showTemplate = (template_id) => {
		setError('');
		setResponseMessage('');
		API.get('/template/show/' + template_id, { headers: { Authorization: props.authData.accessToken } })
			.then((res) => {
				setSelectedTemplate(res.data.data);
				return res;
			})
			.catch((err) => {
				setError(err.message);
			});
		setBtnName('Update');
		props.selectActionButton('Update');
	};

	const handleClickNewTemplate = () => {
		setSelectedTemplate({ id: 0, title: '', subject: '', body: '' });
		setBtnName('Save');
	};

	const openDeleteConfirmAlert = () => {
		confirmAlert({
			title: 'Confirm Delete Template',
			message: 'Are you sure to Delete this template.',
			buttons: [
				{
					label: 'Delete',
					onClick: () => handleRemoveTemplate()
				},
				{
					label: 'Cancel'
				}
			]
		});
	};

	const handleRemoveTemplate = () => {
		if (selectedTemplate.id !== 0) {
			var id = selectedTemplate.id;
			const temp = templates;
			const index = temp.findIndex((obj) => obj.id === id);
			API.delete('/template/remove/' + id, {
				headers: {
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
					Authorization: props.authData.accessToken
				}
			})
				.then((res) => {
					setTemplates([]);
					temp.splice(index, 1);
					setTemplates(sortBy(temp, [ 'title' ]));
					setResponseMessage(res.data.msg);
					setSelectedTemplate({ id: 0, title: '', subject: '', body: '' });
				})
				.catch((err) => {
					setError(err.message);
				});
		} else {
			setError('No templates selected');
		}
	};

	const handleSaveUpdateTemplate = () => {
		if (selectedTemplate.title !== '' && selectedTemplate.body !== '') {
			// let template = [ selected.title, selected.body ];
			let formData = {
				id: selectedTemplate.id,
				title: selectedTemplate.title,
				subject: selectedTemplate.subject,
				body: selectedTemplate.body
			};
			const encodeForm = (data) => {
				return Object.keys(data)
					.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
					.join('&');
			};

			if (btnName === 'Update') {
				API.put('/template/update', encodeForm(formData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						const temp = templates;
						temp.map((template) => {
							if (res.data.data.id === template.id) {
								template.title = res.data.data.title;
								template.subject = res.data.data.subject;
								template.body = res.data.data.body;
							}
							return template;
						});
						setTemplates([]);
						setTemplates(sortBy(temp, [ 'title' ]));
						setResponseMessage(res.data.msg);
						// setSelectedTemplate({ id: 0, title: '', subject: '', body: '' });
					})
					.catch((err) => {
						setError(err.message);
					});
			} else {
				API.post('/template/add', encodeForm(formData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setTemplates((templates) => [ ...templates, res.data.data ]);
						setResponseMessage(res.data.msg);
						// setSelectedTemplate({ id: 0, title: '', subject: '', body: '' });
					})
					.catch((err) => {
						setError(err.message);
					});
			}
		} else {
			setError('No templates selected');
		}
	};

	const selectUsers = (user, event) => {
		setSelectedUsers(user);
		props.selectedRecipientsButton(user);
	};

	const sendMessage = () => {
		let message = selectedTemplate.body;
		let subject = selectedTemplate.subject;
		if (message === '' || subject === '') {
			setError('Enter Message or Subject Text');
		} else {
			let formData = {
				message: message,
				subject: subject
			};

			const encodeForm = (data) => {
				return Object.keys(data)
					.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
					.join('&');
			};

			if (selectedUsers === 'all') {
				API.post('/message/users/send', encodeForm(formData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setResponseMessage(res.data.msg);
						return res;
					})
					.catch((err) => {
						setError(err.message);
					});
			} else if (selectedUsers === 'owner') {
				API.post('/message/owners/send', encodeForm(formData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setResponseMessage(res.data.msg);
						return res;
					})
					.catch((err) => {
						setError(err.message);
					});
			} else {
				let customData = {
					message: message,
					subject: subject,
					recipients: props.selectedRecipients
				};

				API.post('/message/custom/send', customData, {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setResponseMessage(res.data.msg);
						props.selectUsers([]);
						props.selectUsersId([]);
						return res;
					})
					.catch((err) => {
						setError(err.message);
					});
			}
		}
	};

	const sendNotification = () => {
		//transmit socket to send notification to all users
		// if(props.selectedRecipientsId && props.selectedRecipientsId.length) {
		// 	socket.transmit('test',
		// 	[{
		// 		'user_ids' : props.selectedRecipientsId, 
		// 		'template_id' : selectedTemplate.id
		// 	}]); // send notification to selected users
		// } else {
		// 	socket.transmit('test',[]); //broadcast to all users
		// }
		let owners = [];

		data && data.map((key) =>{
			key.owners.map((value) => {
				owners.push(value.id);
			});
		});

		let message = selectedTemplate.body;
		let subject = selectedTemplate.subject;
		if (message === '' || subject === '') {
			setError('Enter Message or Subject Text');
		} else {
			let formData = {
				template_id : selectedTemplate.id
			};

			const encodeForm = (data) => {
				return Object.keys(data)
					.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
					.join('&');
			};

			if (selectedUsers === 'all') {
				API.post('/notify/users/send', encodeForm(formData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setResponseMessage(res.data.msg);
						socket.transmit('broadcast',{
							'user_ids' : [], 
							'template_id' : selectedTemplate.id
						}); //broadcast to all users
						return res;
					})
					.catch((err) => {
						setError(err.message);
					});
			} else if (selectedUsers === 'owner') {
				API.post('/notify/owners/send', encodeForm(formData), {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setResponseMessage(res.data.msg);
						if(owners && owners.length) {
							socket.transmit('broadcast',{
								'user_ids' : owners, 
								'template_id' : selectedTemplate.id
							}); // send notification to selected users
						}
						return res;
					})
					.catch((err) => {
						setError(err.message);
					});
			} else {
				let formData = {
					template_id: selectedTemplate.id,
					recipients: props.selectedRecipients
				};
				API.post('/notify/custom/send', formData, {
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: props.authData.accessToken
					}
				})
					.then((res) => {
						setResponseMessage(res.data.msg);
						props.selectUsers([]);
						props.selectUsersId([]);
						if(props.selectedRecipientsId && props.selectedRecipientsId.length) {
							console.log(props.selectedRecipientsId);
							socket.transmit('broadcast',{
								'user_ids' : props.selectedRecipientsId, 
								'template_id' : selectedTemplate.id
							}); // send notification to selected users
						}
						return res;
					})
					.catch((err) => {
						setError(err.message);
					});
			}
		}
	};

	return (
		<div className="content justify-content-md-center">
			<Card style={{ width: '100%' }}>
				<Card.Body style={{ padding: '0' }}>
					<div className="flex-x p-2">
						<div className="flex-x flex-1">
							<Button variant="primary" size="sm" className="regular">
								Created Templates
								<span> &nbsp;{templates.length}</span>
							</Button>
						</div>
						<div className="flex-x center">
							<Button
								variant="none"
								size="sm"
								style={{ color: '#DC004A' }}
								onClick={() => openDeleteConfirmAlert()}
								disabled={selectedTemplate.title ? false : true}
							>
								Delete
							</Button>
							<Button
								variant="success"
								size="sm"
								onClick={() => handleSaveUpdateTemplate()}
								disabled={selectedTemplate.title ? false : true}
							>
								{btnName}
							</Button>
						</div>
					</div>
				</Card.Body>
			</Card>
			<div className="flex-x p-2">
				<div className="flex-y p-2 mr-5">
					{templates &&
						templates.length &&
						templates.map((template, key) => {
							return (
								<label key={key}>
									<div
										className={template.id === selectedTemplate.id ? 'link link-active' : 'link'}
										onClick={() => showTemplate(template.id)}
									>
										{template.title}
									</div>
								</label>
							);
						})}
					<Button
						variant="none"
						size="sm"
						type="submit"
						style={{ backgroundColor: '#2F2456', color: 'white' }}
						onClick={() => handleClickNewTemplate()}
					>
						Create New Template
					</Button>
				</div>
				<div className="flex-y p-2 flex-1 ml-4">
					<span className="msg">{responseMessage}</span>
					<span className="error">{error}</span>
					<Form>
						<Form.Group>
							<div className="flex-x align-center custom-input mb-2">
								<Form.Label>Template Name:</Form.Label>
								<input
									className="form-control form-control-lg"
									type="text"
									value={selectedTemplate ? selectedTemplate.title : ''}
									onChange={(e) => inputHandler('title', e)}
									required
								/>
							</div>
							<div className="flex-x align-center custom-input mb-2">
								<Form.Label>Email Subject:</Form.Label>
								<input
									className="form-control form-control-lg"
									type="text"
									value={selectedTemplate ? selectedTemplate.subject : ''}
									onChange={(e) => inputHandler('subject', e)}
									required
								/>
							</div>
							<CKEditor
								data={selectedTemplate.body}
								onChange={(event) => editorHandler('body', event)}
								config={{
									height: [ [ '400px' ] ],
									extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
									uploadUrl:
										process.env.REACT_APP_REST_API +
										'/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
									filebrowserUploadUrl:
										process.env.REACT_APP_REST_API +
										'/ckfinder/connector?command=QuickUpload&type=Files',
									filebrowserImageUploadUrl:
										process.env.REACT_APP_REST_API +
										'/ckfinder/connector?command=QuickUpload&type=Images',
									removeDialogTabs: 'image:advanced;link:advanced'
								}}
							/>
						</Form.Group>
						<Form.Group>
							<div className="flex-x">
								<div className="flex-1">
									<label className="form-check-label demi-bold">Recipients</label>
									<Button
										variant={selectedUsers === 'owner' ? 'primary' : 'secondary'}
										size="sm"
										className="regular"
										onClick={(e) => selectUsers('owner', e)}
										disabled={selectedTemplate.title && counts['admins_count'] ? false : true}
									>
										All Company Owners &nbsp;
										<span>{counts['admins_count'] ? counts['admins_count'] : 0}</span>
									</Button>
									<Button
										variant={selectedUsers === 'all' ? 'primary' : 'secondary'}
										size="sm"
										className="regular"
										onClick={(e) => selectUsers('all', e)}
										disabled={selectedTemplate.title && counts['users_count'] ? false : true}
									>
										All Users &nbsp;
										<span>{counts['users_count'] ? counts['users_count'] : 0}</span>
									</Button>
									<Button
										variant={
											props.selectedRecipients && props.recipientsButton === 'custom' ? (
												'primary'
											) : (
												'secondary'
											)
										}
										size="sm"
										className="regular"
										onClick={(e) => {
											selectUsers('custom', e);
											props.selectTemplates(selectedTemplate);
											props.history.push('/custom');
										}}
										disabled={selectedTemplate.title ? false : true}
									>
										Custom &nbsp;
										<span>{props.selectedRecipients ? props.selectedRecipients.length : 0}</span>
									</Button>
								</div>
								<div className="flex-x">
									<Button
										variant="none"
										size="sm"
										style={{ backgroundColor: '#FF0056', color: 'white' }}
										onClick={(e) => sendMessage(e)}
										disabled={selectedTemplate.title && selectedUsers ? false : true}
									>
										Send Email
									</Button>
									<Button
										variant="none"
										size="sm"
										style={{ backgroundColor: '#1384BC', color: 'white' }}
										onClick={(e) => sendNotification(e)}
										disabled={selectedTemplate.title && selectedUsers ? false : true}
									>
										Send Notification
									</Button>
								</div>
							</div>
						</Form.Group>
					</Form>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRecipients: state.customMessage.selectedUsers,
		selectedRecipientsId: state.customMessage.selectedUsersId,
		authData: {
			isLogin: state.auth.isLogin,
			accessToken: state.auth.accessToken,
			authUser: state.auth.authUser
		},
		showDashboardFlag: state.dashboard.showDashboard,
		selectOptionFlag: state.dashboard.selectOption,
		recipientsButton: state.message.selectedRecipients,
		templates: state.message.selectedTemplate,
		actionButton: state.message.actionButton
	};
};

export default compose(
	withRouter,
	connect(mapStateToProps, { selectedRecipientsButton, selectTemplates, selectActionButton, showDashboard, selectOption, selectUsers, selectUsersId })
)(Messages);

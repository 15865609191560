import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
// Redux store provider
import { Provider } from 'react-redux';
import { store } from './redux/store';
// Style Root for making media queries to inline css
// Layout Routes
import layoutRoutes from 'routes/layoutRoutes.jsx';
// import './assets/scss/app.scss';
const hist = createBrowserHistory();

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Router history={hist}>
					<Switch>
						<Route exact path="/" render={() => <Redirect to="/home" />} />
						{layoutRoutes.map((prop, key) => {
							return <Route path={prop.path} component={prop.component} key={key} history={hist} />;
						})}
					</Switch>
				</Router>
			</Provider>
		);
	}
}

export default App;

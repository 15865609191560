import React from 'react';
import { Modal } from 'react-bootstrap';
import Element from './element';

const usersModal = (props) => {
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			// size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Select Users</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Element users={props.users} checked={props.checked} selected={props.selected} />
			</Modal.Body>
		</Modal>
	);
};

export default usersModal;

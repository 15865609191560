import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

const table = (props) => {
	return (
		<BootstrapTable {...props} bordered={props.bordered} selectRow={props.selectRow} rowStyle={props.rowStyle} />
	);
};

export default table;

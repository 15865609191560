import React, { useState, useEffect } from 'react';
import { Card, DropdownButton, Dropdown, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomToggleList from '../common/columnToggle';
import ColumnToggleActions from 'redux/columnToggle/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const { SearchBar, ClearSearchButton } = Search;
const { selectColumn } = ColumnToggleActions;

const tableHeader = (props) => {
	const [ searchInput, setSearchInput ] = useState(null);
	const [ columnToggleList, setColumnToggleList ] = useState([]);

	useEffect(
		() => {
			if (props.selectedColumns) {
				setColumnToggleList(props.selectedColumns);
			}
		},
		[ props.selectedColumns ]
	);

	props.columnToggleProps.columns.forEach((column) => {
		if (!props.columnToggleProps.toggles.hasOwnProperty('' + column.dataField + '')) {
			props.columnToggleProps.toggles[column.dataField] = true;
		}
		if (columnToggleList && columnToggleList.length && columnToggleList.includes(column.dataField)) {
			column.csvExport = true;
		} else {
			column.csvExport = false;
		}
	});

	useEffect(
		() => {
			let cols = props.columnToggleProps.columns;
			cols.forEach((column) => {
				if (columnToggleList && columnToggleList.length && columnToggleList.includes(column.dataField)) {
					column.csvExport = true;
					props.columnToggleProps.toggles[column.dataField] = true;
				} else {
					column.csvExport = false;
					props.columnToggleProps.toggles[column.dataField] = false;
				}
			});
		},
		[ columnToggleList ]
	);

	const handleColumnToggleList = (column) => {
		let columnList = columnToggleList ? columnToggleList : [];
		// console.log('set in state', column);
		if (columnList.includes(column)) {
			const index = columnList.indexOf(column);
			setColumnToggleList((columnList) => {
				const selectedvalues = columnList;
				selectedvalues.splice(index, 1);
				props.selectColumn(selectedvalues);
				return [ ...selectedvalues ];
			});
		} else {
			setColumnToggleList((columnList) => {
				// console.log('columnList', columnList);
				props.selectColumn([ ...columnList, column ]);
				return [ ...columnList, column ];
			});
		}
	};

	const clearSearchBar = () => {
		setSearchInput(null);
	};

	const handleClick = () => {
		props.onExport();
	};

	return (
		<Card style={{ width: '100%' }}>
			<Card.Body style={{ padding: '0' }}>
				<div className="flex-x p-2">
					<div className="flex-x flex-0 center">
						<div className="flex-x center text-black">
							Show
							<SizePerPageDropdownStandalone
								{...props.paginationProps}
								btnContextual="none"
								className="custom-dropdown-button"
							/>
							entries
						</div>
						<DropdownButton variant="light" title="Column visibility" size="sm">
							<CustomToggleList
								{...props.columnToggleProps}
								contextual="none"
								columnToggle={(column) => handleColumnToggleList(column)}
								className="btn-group-vertical sm toggler"
							/>
						</DropdownButton>
					</div>
					<div className="flex-x center flex-1">
						<ButtonGroup size="sm">
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Active companies( active companies from studio, agency, free, paid)
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'all' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.selectedButtonHandler('all')}
								>
									Total &nbsp;<span>{props.counts ? props.counts.active : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="bottom"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-bottom`}>
										Paid companies(studio, agency, previous version paid companies and paid-inactive
										companies)
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'paid' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.selectedButtonHandler('paid')}
									disabled={props.counts ? props.counts.paid === 0 ? true : false : true}
								>
									Paid &nbsp;<span>{props.counts ? props.counts.paid : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Free Trial, trial expired, free companies and free-inactive companies
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'free' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.selectedButtonHandler('free')}
									disabled={props.counts ? props.counts.free === 0 ? true : false : true}
								>
									Free Accounts &nbsp;<span>{props.counts ? props.counts.free : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Studio plan companies(includes studio-inactive companies)
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'studio' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.selectedButtonHandler('studio')}
									disabled={props.counts ? props.counts.studio === 0 ? true : false : true}
								>
									Studio&nbsp;<span>{props.counts ? props.counts.studio : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Agency plan companies(includes agency-inactive companies)
									</Tooltip>
								}
							>
								<Button
									variant={props.selectedButton === 'agency' ? 'primary' : 'secondary'}
									className="regular"
									onClick={() => props.selectedButtonHandler('agency')}
									disabled={props.counts ? props.counts.agency === 0 ? true : false : true}
								>
									Agency&nbsp;<span>{props.counts ? props.counts.agency : 0}</span>
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={<Tooltip id={`tooltip-top`}>Closed Accounts</Tooltip>}
							>
								<Button
									variant="danger"
									className="regular"
									onClick={() => props.selectedButtonHandler('closed')}
									disabled={props.counts ? props.counts.closed === 0 ? true : false : true}
								>
									Closed &nbsp;<span>{props.counts ? props.counts.closed : 0}</span>
								</Button>
							</OverlayTrigger>
						</ButtonGroup>
					</div>
					<div className="flex-x center">
						<SearchBar
							{...props.searchProps}
							placeholder="&#xF002; Filter Companies"
							className="search-box fontAwsome"
							onChange={(e) => setSearchInput(e.target.value)}
							value={searchInput}
						/>

						<ClearSearchButton
							className="btn bg-transparent clear-button fa fa-times"
							{...props.searchProps}
							onClick={clearSearchBar}
							text=""
						>
							<i className="fa fa-times" />
						</ClearSearchButton>

						<DropdownButton
							variant="secondary"
							title={props.dropdownButtonTitle}
							id="bg-vertical-dropdown-1"
							size="sm"
							className="custom-dropdown-button"
						>
							<Dropdown.Item
								eventKey="2"
								className={props.filterKey === -1 ? 'selected' : ''}
								onClick={() => props.selectDropdownButton('All Time', -1)}
							>
								All Time
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="2"
								className={props.filterKey === 7 ? 'selected' : ''}
								onClick={() => props.selectDropdownButton('Last 7 days', 7)}
							>
								Last 7 days
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="3"
								className={props.filterKey === 30 ? 'selected' : ''}
								onClick={() => props.selectDropdownButton('Last 30 days', 30)}
							>
								Last 30 days
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="4"
								className={props.filterKey === 90 ? 'selected' : ''}
								onClick={() => props.selectDropdownButton('Last 90 days', 90)}
							>
								Last 90 days
							</Dropdown.Item>
							<div
								className={props.filterKey === 0 ? 'custom-menu-pad selected' : 'custom-menu-pad'}
								onClick={() => props.selectDropdownButton('Custom', 0)}
							>
								Custom
							</div>
							<div className={props.filterKey === 0 ? 'showCalender show' : 'showCalender hide'}>
								<DatePicker
									selected={props.startDate}
									selectsStart
									startDate={props.startDate}
									endDate={props.endDate}
									onChange={(e) => props.handleChangeStart(e)}
									maxDate={new Date()}
								/>
								<DatePicker
									selected={props.endDate}
									selectsEnd
									startDate={props.startDate}
									endDate={props.endDate}
									onChange={(e) => props.handleChangeEnd(e)}
									minDate={props.startDate}
									maxDate={new Date()}
								/>
								<Button variant="primary" size="sm" onClick={() => props.filterDataCustomDateWise()}>
									Apply
								</Button>
							</div>
						</DropdownButton>

						<DropdownButton
							variant="secondary"
							title="Export"
							id="bg-vertical-dropdown-1"
							size="sm"
							className="custom-dropdown-button"
						>
							<Dropdown.Item eventKey="1" onClick={handleClick}>
								CSV
							</Dropdown.Item>
						</DropdownButton>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedColumns: state.columnToggle && state.columnToggle.columns
	};
};

export default compose(withRouter, connect(mapStateToProps, { selectColumn }))(React.memo(tableHeader));

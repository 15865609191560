import auth from './auth/reducer';
import customMessage from './customMessage/reducer';
import dashboard from './dashboard/reducer';
import message from './message/reducer';
import userDetail from './userDetail/reducer';
import columnToggle from './columnToggle/reducer';
import usersList from './usersList/reducer';

export default {
	auth,
	customMessage,
	dashboard,
	message,
	userDetail,
	columnToggle,
	usersList
};

const setRecipientButtonActions = {
	SELECTED_RECIPIENTS_BUTTON: 'SELECTED_RECIPIENTS_BUTTON',
	SELECTED_TEMPLATES: 'SELECTED_TEMPLATES',
	ACTION_BUTTON: 'ACTION_BUTTON',

	selectedRecipientsButton: (recipientButton) => {
		return {
			type: setRecipientButtonActions.SELECTED_RECIPIENTS_BUTTON,
			recipientButton: recipientButton
		};
	},

	selectTemplates: (template) => {
		return {
			type: setRecipientButtonActions.SELECTED_TEMPLATES,
			selectedTemplate: template
		};
	},

	selectActionButton: (actionButton) => {
		return {
			type: setRecipientButtonActions.ACTION_BUTTON,
			actionButton: actionButton
		};
	},

};

export default setRecipientButtonActions;

import React, { useState, useEffect } from 'react';
import Table from '../../components/table/table';
import TableHeader from '../../components/table/tableHeader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import API from '../../api';
import { compose } from 'redux';
import { Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Switch } from 'pretty-checkbox-react';
import ShowDashboardActions from 'redux/dashboard/actions';
import ShowUserListActions from 'redux/usersList/actions';

const { showDashboard, selectOption } = ShowDashboardActions;
const { showUserList } = ShowUserListActions;

const Company = (props) => {
	const [ companyData, setCompanyData ] = useState(null);
	const [ closedCompanyData, setClosedCompanyData ] = useState(null);
	const [ counts, setCounts ] = useState(null);
	const [ filterKey, setFilterKey ] = useState(-1);
	const [ selectedButton, setSelectedButton ] = useState('all');
	const [ startDate, setStartDate ] = useState(new Date());
	const [ endDate, setEndDate ] = useState(new Date());
	const [ selected, setSelected ] = useState([]);
	const [ dropdownButtonTitle, setDropdownButtonTitle ] = useState('All Time');

	useEffect(
		() => {
			const selectedOpt = props.dashboard.selectedOption;

			if (counts && selectedOpt === 'Total Companies') {
				setSelectedButton('all');
			} else if (counts && selectedOpt === 'Paid' && counts.paid && counts.paid > 0) {
				setSelectedButton('paid');
			} else if (counts && selectedOpt === 'Free Accounts' && counts.free && counts.free > 0) {
				setSelectedButton('free');
			} else if (counts && selectedOpt === 'Closed' && counts.closed && counts.closed > 0) {
				setSelectedButton('closed');
			} else if (counts && selectedOpt === 'Studio' && counts.studio && counts.studio > 0) {
				setSelectedButton('studio');
			} else if (counts && selectedOpt === 'Agency' && counts.agency && counts.agency > 0) {
				setSelectedButton('agency');
			} else {
				if (!selectedButton) {
					setSelectedButton('all');
				}
			}
		},
		[ counts ]
	);

	useEffect(() => {
		props.showDashboard(null);
	}, []);

	useEffect(
		() => {
			let url = '';
			if (filterKey !== 0) {
				setCompanyData(null);
				if (filterKey === -1) {
					url = '/listcompany';
				} else {
					url = '/listcompany/' + filterKey;
				}
				API.get(url, { headers: { Authorization: props.authData.accessToken } })
					.then((res) => {
						let d = res.data.data.companyData;
						setCounts({
							total: res.data.data.total,
							free: res.data.data.free,
							trial_expired: res.data.data.trial_expired,
							paid: res.data.data.paid,
							closed: res.data.data.closed,
							active: res.data.data.active,
							studio: res.data.data.studio,
							agency: res.data.data.agency
						});

						if (selectedButton === 'free' && res.data.data.free === 0) {
							setSelectedButton('all');
						} else if (selectedButton === 'trial_expired' && res.data.data.trial_expired === 0) {
							setSelectedButton('all');
						} else if (selectedButton === 'paid' && res.data.data.paid === 0) {
							setSelectedButton('all');
						} else if (selectedButton === 'closed' && res.data.data.closed === 0) {
							setSelectedButton('all');
						} else if (selectedButton === 'studio' && res.data.data.studio === 0) {
							setSelectedButton('all');
						} else if (selectedButton === 'agency' && res.data.data.agency === 0) {
							setSelectedButton('all');
						} else if (selectedButton === 'active' && res.data.data.active === 0) {
							setSelectedButton('all');
						} else {
						}
						columns = commonColumns;
						setCompanyData(d);
						setClosedCompanyData(res.data.data.closedCompanyData);
					})
					.catch((err) => err);
			}
		},
		[ filterKey ]
	);

	const linkFormatter = (cell) => {
		return (
			<a href={'mailto:' + cell} className="table-link word-break">
				{cell}
			</a>
		);
	};

	let commonColumns = [
		{
			dataField: 'key',
			text: 'Index',
			sort: true
		},
		{
			dataField: 'company_name',
			text: 'Company Name',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return (
					<div className="table-link word-break" onClick={() => redirectDashboard(row)}>
						{row.company_name}
					</div>
				);
			}
		},
		{
			dataField: 'admin_first',
			text: 'Admin First',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return <div className="table-link word-break">{row.admin_first}</div>;
			},
			csvFormatter: (cell, row, rowIndex) => `${row.admin_first}`
		},
		{
			dataField: 'admin_last',
			text: 'Admin Last',
			csvExport: true,
			sort: true,
			formatter: (value, row) => {
				return <div className="table-link word-break">{row.admin_last}</div>;
			},
			csvFormatter: (cell, row, rowIndex) => `${row.admin_last}`
		},
		{
			dataField: 'admin_email',
			text: 'Admin Email',
			sort: true,
			// csvExport: false,
			formatter: linkFormatter
		},
		{
			dataField: 'uri',
			text: 'Team-name',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return <div className="word-break">{row.uri}</div>;
			}
		},
		{
			dataField: 'no_of_users',
			text: 'No of Users',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return (
					<div className="table-link" onClick={() => redirectToUsersList(row)}>
						{row.no_of_users}
					</div>
				);
			}
		},
		{
			dataField: 'company_created_sort',
			text: 'Date Created',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				if (row.id) {
					return <div>{row.date_created}</div>;
				}
			}
		},
		{
			dataField: 'last_active_date',
			text: 'Last Active',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				if (row.id) {
					return <div>{row.last_active}</div>;
				}
			}
		},
		{
			dataField: 'workspaces_created',
			text: 'Workspaces Created',
			sort: true
			// csvExport: false
		},
		{
			dataField: 'messages_sent',
			text: 'Messages Sent',
			sort: true
			// csvExport: false
		},
		{
			dataField: 'tasks_created',
			text: 'Tasks Created',
			sort: true
			// csvExport: false
		},
		{
			dataField: 'tasks_completed',
			text: 'Tasks Completed',
			sort: true
			// csvExport: false
		},
		{
			dataField: 'comments_created',
			text: 'Comments Created',
			sort: true
			// csvExport: false
		},
		{
			dataField: 'storage_size',
			text: 'Storage Size',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return <div>{+row.storage_size} GB</div>;
			}
		},
		{
			dataField: 'base_plan',
			text: 'Plan',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return <div>{row.base_plan}</div>;
			}
		},
		{
			dataField: 'storage_plan',
			text: 'Storage',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				return <div>{row.storage_plan}</div>;
			}
		},
		{
			dataField: 'status',
			text: 'Company Status',
			sort: true,
			// csvExport: false,
			formatter: (value, row) => {
				if (row.id) {
					return (
						<div>
							<Switch
								type="checkbox"
								color="success"
								shape="fill"
								checked={row.status === 'active' ? true : false}
								onChange={() => openChangeStatusConfirmAlert(row)}
							/>
						</div>
					);
				}
			}
		},
		{
			dataField: 'button',
			text: 'Action',
			csvExport: false,
			formatter: (value, row) => {
				if (row.id) {
					return (
						<div>
							<Button
								variant="danger"
								size="sm"
								onClick={() => openDeleteConfirmAlert(row)}
								type="submit"
								data-toggle="tooltip"
								title="Delete Company"
								className="btn-delete"
							>
								Delete
							</Button>
						</div>
					);
				}
			}
		}
	];

	var columns = commonColumns;

	let specialColumns = [
		{
			dataField: 'key',
			text: 'Index',
			sort: true
		},
		{
			dataField: 'company_name',
			text: 'Company Name',
			sort: true
		},
		{
			dataField: 'admin_first',
			text: 'Admin Firstname',
			sort: true
		},
		{
			dataField: 'admin_last',
			text: 'Admin Lastname',
			sort: true
		},
		{
			dataField: 'admin_email',
			text: 'Admin Email',
			sort: true,
			formatter: linkFormatter
		},
		{
			dataField: 'closed_date',
			text: 'Closed Date',
			sort: true,
			toggle: false
		},
		{
			dataField: 'reason_for_closing',
			text: 'Reason of Cancelation',
			sort: true,
			toggle: false,
			formatter: (value, row) => {
				if (row.reason_for_closing && row.reason_for_closing.length) {
					return (
						<ul>
							{row.reason_for_closing.map((reason, key) => {
								return <li key={key}>{reason}</li>;
							})}
						</ul>
					);
				}
				return <div>{row.reason_for_closing}</div>;
			}
		},
		{
			dataField: 'description',
			text: 'Description',
			sort: true,
			toggle: false
		},
		{
			dataField: 'button',
			text: 'Action',
			csvExport: false,
			formatter: (value, row) => {
				if (row.id) {
					return (
						<div>
							<Button
								variant="danger"
								size="sm"
								onClick={() => openDeleteConfirmAlert(row)}
								type="submit"
								data-toggle="tooltip"
								title="Delete Entry"
								className="btn-delete"
							>
								Delete
							</Button>
						</div>
					);
				}
			}
		}
	];

	let filteredData = null;
	if (companyData && companyData.length) {
		if (selectedButton === 'all') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0.0;
			filteredData = companyData.filter((data, key) => {
				if ((data && data.status === 'active') || (data && data.id === 0)) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else if (selectedButton === 'paid') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0;
			filteredData = companyData.filter((data, key) => {
				if ((data && data.subscription_status === 'active') || (data && data.id === 0)) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else if (selectedButton === 'free') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0;
			filteredData = companyData.filter((data, key) => {
				if (
					(data && data.subscription_status === 'trialing') ||
					(data && data.subscription_status === 'free') ||
					(data && data.id === 0)
				) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else if (selectedButton === 'trial_expired') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0;
			filteredData = companyData.filter((data, key) => {
				if ((data && data.subscription_status === 'trial_expired') || (data && data.id === 0)) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else if (selectedButton === 'active') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0;
			filteredData = companyData.filter((data, key) => {
				if ((data && data.status === 'active') || (data && data.id === 0)) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else if (selectedButton === 'agency') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0;
			filteredData = companyData.filter((data, key) => {
				if ((data && data.base_plan === 'Agency') || (data && data.id === 0)) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else if (selectedButton === 'studio') {
			companyData[0].comments_created = 0;
			companyData[0].messages_sent = 0;
			companyData[0].no_of_users = 0;
			companyData[0].tasks_created = 0;
			companyData[0].tasks_completed = 0;
			companyData[0].workspaces_created = 0;
			companyData[0].storage_size = 0;
			filteredData = companyData.filter((data, key) => {
				if ((data && data.base_plan === 'Studio') || (data && data.id === 0)) {
					if (data && data.id !== 0) {
						companyData[0].comments_created += data.comments_created;
						companyData[0].messages_sent += data.messages_sent;
						companyData[0].no_of_users += data.no_of_users;
						companyData[0].tasks_created += data.tasks_created;
						companyData[0].tasks_completed += data.tasks_completed;
						companyData[0].workspaces_created += data.workspaces_created;
						companyData[0].storage_size += +data.storage_size;
					}
					return data;
				}
				return null;
			});
		} else {
			filteredData = companyData;
		}
	}

	if (closedCompanyData && closedCompanyData.length) {
		if (selectedButton === 'closed') {
			columns = null;
			columns = specialColumns;
			// setSelectedColumns(1);
			filteredData =
				closedCompanyData &&
				closedCompanyData.length &&
				closedCompanyData.map((data, key) => {
					return data;
				});
		}
	}

	const redirectToUsersList = (company) => {
		if (company.company_name !== 'All Companies') {
			let comp = { id: company.id, company_name: company.company_name };
			props.showUserList(comp);
		} else {
			props.showUserList(null);
		}
		props.history.push('/viewUsers');
	};

	const defaultSorted = [
		{
			dataField: 'id',
			order: 'asc'
		}
	];

	let options;

	const handleDropdownButtonSelect = (title, filterKey) => {
		setFilterKey(() => filterKey);
		setDropdownButtonTitle(() => title);
	};

	const openChangeStatusConfirmAlert = (row) => {
		var status = row.status === 'active' ? 'Inactivate' : 'Activate';
		confirmAlert({
			title: `Confirm ${status} Company`,
			message: 'Are you sure to ' + status + ' this company.',
			buttons: [
				{
					label: status,
					onClick: () => companySubscriptionHandler(row)
				},
				{
					label: 'Cancel'
				}
			]
		});
	};

	const openDeleteConfirmAlert = (row) => {
		confirmAlert({
			title: 'Confirm Delete Entry',
			message: 'Are you sure to Delete this log.',
			buttons: [
				{
					label: 'Delete',
					onClick: (e) => handleClickDeleteCompany(e, row)
				},
				{
					label: 'Cancel'
				}
			]
		});
	};

	const handleClickDeleteCompany = (event, company) => {
		if (selectedButton === 'closed') {
			const temp = closedCompanyData;
			const index = temp.findIndex((obj) => obj.id === company.id);
			setClosedCompanyData((closedCompanyData) => {
				const selectedvalues = closedCompanyData;
				selectedvalues.splice(index, 1);
				return [ ...selectedvalues ];
			});
			API.get('/company/deleteClosedCompany/' + company.id, {
				headers: { Authorization: props.authData.accessToken }
			})
				.then((res) => {
					setClosedCompanyData(temp);
					setCounts({
						total: res.data.data.total,
						free: res.data.data.free,
						trial_expired: res.data.data.trial_expired,
						paid: res.data.data.paid,
						closed: res.data.data.closed
					});
				})
				.catch((err) => {
					return err;
				});
		} else {
			const temp = companyData;
			const index = temp.findIndex((obj) => obj.id === company.id);
			setCompanyData((companyData) => {
				const selectedvalues = companyData;
				selectedvalues.splice(index, 1);
				return [ ...selectedvalues ];
			});

			API.get('/company/remove/' + company.id, { headers: { Authorization: props.authData.accessToken } })
				.then((res) => {
					setCompanyData(temp);
					setCounts({
						total: res.data.data.total,
						free: res.data.data.free,
						trial_expired: res.data.data.trial_expired,
						paid: res.data.data.paid,
						closed: res.data.data.closed
					});
				})
				.catch((err) => {
					return err;
				});
		}
	};

	const handleDeleteMultipleCompany = () => {
		const selectedCompanies = selected;
		const temp = companyData;

		selectedCompanies &&
			selectedCompanies.length &&
			selectedCompanies.forEach((company) => {
				setCompanyData((companyData) => {
					const index = temp.findIndex((obj) => obj.id === company);
					const selectedvalues = companyData;
					selectedvalues.splice(index, 1);
					return [ ...selectedvalues ];
				});
			});

		API.post(
			'/company/removeMulti',
			{ ids: JSON.parse(JSON.stringify(selectedCompanies)) },
			{
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
					Authorization: props.authData.accessToken
				}
			}
		)
			.then((res) => {
				setSelected([]);
				setCounts({
					total: res.data.data.total,
					free: res.data.data.free,
					trial_expired: res.data.data.trial_expired,
					paid: res.data.data.paid,
					closed: res.data.data.closed
				});
			})
			.catch((err) => {
				return err;
			});
	};

	const handleDeactivateMultipleCompany = () => {
		const selectedCompanies = selected;

		API.post(
			'/deactivateMulti',
			{ ids: JSON.parse(JSON.stringify(selectedCompanies)) },
			{
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
					Authorization: props.authData.accessToken
				}
			}
		)
			.then((res) => {
				selectedCompanies &&
					selectedCompanies.length &&
					selectedCompanies.forEach((company) => {
						setCompanyData((companyData) => {
							return [
								...companyData,
								...companyData.map((data) => {
									if (data && data.id === company) {
										return (data.status = 'inactive');
									}
									return;
								})
							];
						});
					});
			})
			.catch((err) => {
				return err;
			});
	};

	const redirectDashboard = (company) => {
		if (company.company_name !== 'All Companies') {
			let comp = { id: company.id, company_name: company.company_name };
			props.showDashboard(comp);
		} else {
			props.showDashboard(null);
		}
		props.history.push('/home');
	};

	const companySubscriptionHandler = (company) => {
		if (company.status === 'inactive') {
			API.get('/activate/' + company.id, { headers: { Authorization: props.authData.accessToken } })
				.then((res) => {
					setCompanyData((companyData) => {
						return [
							...companyData,
							...companyData.map((data) => {
								if (data && data.id === company.id) {
									return (data.status = 'active');
								}
							})
						];
					});
				})
				.catch((err) => {
					return err;
				});
		} else {
			API.get('/deactivate/' + company.id, { headers: { Authorization: props.authData.accessToken } })
				.then((res) => {
					setCompanyData((companyData) => {
						return [
							...companyData,
							...companyData.map((data) => {
								if (data && data.id === company.id) {
									return (data.status = 'inactive');
								}
							})
						];
					});
				})
				.catch((err) => {
					return err;
				});
		}
	};

	if (filteredData && filteredData.length) {
		options = {
			custom: true,
			totalSize: filteredData.length,
			sizePerPageList: [
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				},
				{
					text: 'All',
					value: filteredData.length
				}
			]
		};
	}

	const handleChangeEnd = (e) => {
		setEndDate(() => e);
	};

	const handleChangeStart = (e) => {
		setStartDate(() => e);
	};

	const filterDataCustomDateWise = () => {
		var start_date = startDate;
		var end_date = endDate;
		var iso_startdate_string = start_date.toISOString();
		var iso_enddate_string = end_date.toISOString();

		API.get('/listcompany/' + iso_startdate_string + '/' + iso_enddate_string, {
			headers: { Authorization: props.authData.accessToken }
		})
			.then((res) => {
				let d = res.data.data.companyData;
				setClosedCompanyData(res.data.data.closedCompanyData);
				setCounts({
					total: res.data.data.total,
					free: res.data.data.free,
					trial_expired: res.data.data.trial_expired,
					paid: res.data.data.paid,
					closed: res.data.data.closed,
					active: res.data.data.active,
					studio: res.data.data.studio,
					agency: res.data.data.agency
				});

				if (selectedButton === 'free' && res.data.data.free === 0) {
					setSelectedButton('all');
				} else if (selectedButton === 'trial_expired' && res.data.data.trial_expired === 0) {
					setSelectedButton('all');
				} else if (selectedButton === 'paid' && res.data.data.paid === 0) {
					setSelectedButton('all');
				} else if (selectedButton === 'closed' && res.data.data.closed === 0) {
					setSelectedButton('all');
				} else if (selectedButton === 'active' && res.data.data.active === 0) {
					setSelectedButton('all');
				} else {
				}
				setCompanyData(d);
			})
			.catch((err) => err);
	};

	const selectRow = {
		mode: 'checkbox',
		style: { backgroundColor: 'rgba(0, 0, 0, 0.075)' },
		hideSelectColumn: selectedButton === 'closed' ? true : false,
		selectColumnStyle: ({ checked, disabled, rowIndex, rowKey }) => {
			if (rowIndex === 0) {
				return {
					visibility: 'hidden'
				};
			}
		},
		onSelect: (row, isSelect, rowIndex, e) => {
			if (isSelect) {
				setSelected((selected) => [ ...selected, row.id ]);
			} else {
				const temp = selected;
				const index = selected.findIndex((obj) => obj.id === row.id);
				setSelected([]);
				temp.splice(index, 1);
				setSelected(temp);
			}
		},
		onSelectAll: (isSelect, rows, e) => {
			if (isSelect) {
				setSelected((selected) => {
					return [
						...selected,
						...rows.map((row) => {
							if (row.id !== 0) return row.id;
						})
					];
				});
			} else {
				setSelected([]);
			}
		}
	};

	return (
		<div className="content justify-content-md-center">
			<h5 className="title">All Companies Data</h5>
			{filteredData && filteredData.length && counts ? (
				<ToolkitProvider
					bootstrap4
					keyField="key"
					columns={columns}
					data={filteredData}
					defaultSorted={defaultSorted}
					search
					columnToggle
					exportCSV={{
						fileName: new Date() + '_companies.csv',
						exportAll: false,
						onlyExportFiltered: false
					}}
				>
					{(props) => (
						<PaginationProvider pagination={paginationFactory(options)}>
							{({ paginationProps, paginationTableProps }) => (
								<div>
									<TableHeader
										{...props.csvProps}
										counts={counts}
										paginationProps={paginationProps}
										columnToggleProps={props.columnToggleProps}
										searchProps={props.searchProps}
										selectedButtonHandler={setSelectedButton}
										filterKey={filterKey}
										startDate={startDate}
										endDate={endDate}
										selectDropdownButton={handleDropdownButtonSelect}
										filterKeyHandler={setFilterKey}
										selectedButton={selectedButton}
										handleChangeEnd={handleChangeEnd}
										dropdownButtonTitle={dropdownButtonTitle}
										handleChangeStart={handleChangeStart}
										filterDataCustomDateWise={filterDataCustomDateWise}
									/>
									<div className="p-2">
										<div className="flex-x flex-0 list-flex">
											<PaginationListStandalone {...paginationProps} />
											<DropdownButton
												variant="secondary"
												title="Actions"
												id="bg-vertical-dropdown-1"
												size="sm"
												className={
													selectedButton === 'closed' ? (
														'custom-dropdown-button hide'
													) : (
														'custom-dropdown-button'
													)
												}
											>
												<Dropdown.Item
													eventKey="0"
													onClick={() => {
														if (selected.length > 0) {
															confirmAlert({
																title: 'Confirm Delete Companies',
																message: 'Are you sure to Delete these companies.',
																buttons: [
																	{
																		label: 'Delete',
																		onClick: () => handleDeleteMultipleCompany()
																	},
																	{
																		label: 'Cancel'
																	}
																]
															});
														} else {
															alert('Please select companies');
														}
													}}
												>
													Delete
												</Dropdown.Item>
												<Dropdown.Item
													eventKey="1"
													onClick={() => {
														if (selected.length > 0) {
															confirmAlert({
																title: 'Confirm deactive Company',
																message: 'Are you sure to deactivate this company?',
																buttons: [
																	{
																		label: 'Deactivate',
																		onClick: () => handleDeactivateMultipleCompany()
																	},
																	{
																		label: 'Cancel'
																	}
																]
															});
														} else {
															alert('Please select companies');
														}
													}}
												>
													Deactivate
												</Dropdown.Item>
											</DropdownButton>
										</div>
										<Table
											striped
											hover
											condensed
											selectRow={selectRow}
											bordered={false}
											{...paginationTableProps}
											{...props.baseProps}
										/>
									</div>
								</div>
							)}
						</PaginationProvider>
					)}
				</ToolkitProvider>
			) : (
				<div className="d-flex justify-content-center">
					<div
						className="spinner-border text-primary m-5"
						role="status"
						style={{ width: '4rem', height: '4rem' }}
					>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authData: {
			isLogin: state.auth.isLogin,
			accessToken: state.auth.accessToken
		},
		dashboard: {
			selectedOption: state.dashboard.selectOption
		}
	};
};

export default compose(withRouter, connect(mapStateToProps, { showDashboard, selectOption, showUserList }))(
	React.memo(Company)
);

import Dashboard from 'views/Dashboard/Dashboard';
import Messages from 'views/Messages/Messages';

var dashboardRoutes = [
	{
		path: '/home',
		name: 'Dashboard',
		icon: 'fa fa-home',
		component: Dashboard
	},
	{
		path: '/messages',
		name: 'Messages',
		icon: 'fa fa-envelope',
		component: Messages
	}
];

export default dashboardRoutes;

import SocetCluster from "socketcluster-client";

// const options = {
//   port: 8001,
//   hostname: '192.168.1.148',
//   disconnectOnUnload: false,
//   secure: false
// };
const options = {
  port: process.env.REACT_APP_SOCKET_PORT,
  hostname: process.env.REACT_APP_SOCKET_URL,
  disconnectOnUnload: false,
  secure: true
};
// const options = {
//   path: '/socketcluster/',
//   port: 8000,
//   hostname: '127.0.0.1'
// }

const socket = SocetCluster.create(options);

// socket.on('connect', () => {
//   console.log('connect')
// })
export default socket;
const showUserListActions = {
	SELECTED_COMPANY: 'SELECTED_COMPANY',

	showUserList: (company) => {
		return {
			type: showUserListActions.SELECTED_COMPANY,
			company: company
		};
	}
};

export default showUserListActions;

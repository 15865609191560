import showDashboardActions from './actions';

const initState = {
	showDashboard: null,
	selectOption: null
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case showDashboardActions.SELECTED_COMPANY:
			return {
				...state,
				showDashboard: action.company
			};
		case showDashboardActions.SELECTED_OPTION:
			return {
				...state,
				selectOption: action.option
			};
		default:
			return state;
	}
}

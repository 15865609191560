import React from 'react';

const footer = () => {
	return (
		<footer>
			<div className="text-center py-3 footer">
				<span>&copy; COPYRIGHT 2019 VABOTU. &nbsp;</span>
				ALL RIGHTS RESERVED. | &nbsp;
				<a href="#">PRIVACY POLICY</a> | &nbsp;
				<a href="#">TERMS AND CONDITIONS</a>
			</div>
		</footer>
	);
};

export default footer;

const showDashboardActions = {
	SELECTED_COMPANY: 'SELECTED_COMPANY',
	SELECTED_OPTION: 'SELECTED_OPTION',

	showDashboard: (company) => {
		return {
			type: showDashboardActions.SELECTED_COMPANY,
			company: company
		};
	},

	selectOption: (option) => {
		return {
			type: showDashboardActions.SELECTED_OPTION,
			option: option
		};
	}
};

export default showDashboardActions;
